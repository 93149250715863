import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import AddIcon from "../../../../assets/images/listingManager/addIcon.svg"
import "./ListingManagerList.scss"
import ListingManagerDetail from "../ListingManagerDetail/ListingManagerDetail";
import { getMethodWithToken } from '../../../../utils/services/apis';
import url from '../../../../utils/services/urls.json'
import DefaultImage from "../../../../assets/images/default-image.svg"
import { navigateLogin } from '../../../../utils/services/common';
import shareIcon from "../../../../assets/images/listingManager/share-list.svg"
import PhoneIcon from "../../../../assets/images/listingManager/call.svg"
import Mail from "../../../../assets/images/listingManager/mail.svg"
import Message from "../../../../assets/images/listingManager/sms.svg"
import { Col, Row } from 'react-bootstrap';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowBackChat from "../../../../assets/images/arrow-back-chat.svg"
const ManagerList = styled.div`
margin-top: 30px;
width:100%;
`
const ListingManagerList = ({ contactModal, mobileModal,handleImageListModal }) => {
    const navigate = useNavigate()
    const [listDetail, setListDetail] = useState(false)
    const [managerId, setManagerId] = useState('')
    const [listArray, setListArray] = useState([])
    const [listingDetails, setListingdetails] = useState([])
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const handleAddManager = () => {
        navigate("AddManager", { state: { "id": "AddManager" } })
    }
    const handleListdetail = (value) => {
        setListDetail(true)

        // let arr = [...listArray]
        // arr.map((item, index) => (
        //     arr[index].isSelect = false
        // ))
        // const index = arr.findIndex(item => item.id === value.id);
        // arr[index].isSelect = true
        // setListArray(arr)
        setManagerId(value?.id)
    }

    const handleManagerList = async () => {
        await getMethodWithToken(url.managerList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setListArray(response?.data?.manager_list)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    useEffect(() => {
        handleManagerList()
    }, [])

    useEffect(() => {
        if (managerId) {
            handleManagerDetail()
        }
    }, [managerId])

    const handleArrowchange = () => {
        setListDetail(false)
        setManagerId('')
        
    }

    const handleManagerDetail = async () => {
        await getMethodWithToken(url.managerDetails + managerId + '/').then((response) => {
            if (response.status == 200 || response.status == 201) {
                setListingdetails(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    return (
        <div className="property-manager" >
            <Row>
                {(!listDetail || screenSize.width > 1200) &&
                    <Col xs={12} sm={12} md={7} lg={7} xl={4} xxl={4}>
                        <div className="manager-list-card" style={{ height: (listArray?.length <= 7 && screenSize.width < 390) ? "80vh" : (listArray?.length <= 7 && screenSize.width < 400) ? "81vh" : (listArray?.length <= 7 && screenSize.width < 432) ? "83vh" : (listArray?.length <= 7 && screenSize.width < 542) ? "80vh" : (listArray?.length <= 7 && screenSize.width < 575) ? "83vh" : "auto" }}>
                            <div className="d-flex justify-content-between manager-list">
                                <div className="manager-heading">Managers</div>
                                <div className="d-flex" >
                                    <div className="d-flex align-items-center">
                                        <img src={AddIcon} alt="" />
                                    </div>
                                    <div className="manager-add-heading" onClick={() => handleAddManager()}>Add a Manager</div>
                                </div>
                            </div>
                            <div className="manager-card" style={{ boxShadow: "0px 2px 8px 0px #0000001F", height: (listArray?.length <= 7 && screenSize.width < 390) ? "80vh" : (listArray?.length <= 7 && screenSize.width < 400) ? "81vh" : (listArray?.length <= 7 && screenSize.width < 432) ? "83vh" : (listArray?.length <= 7 && screenSize.width < 542) ? "80vh" : (listArray?.length <= 7 && screenSize.width < 575) ? "83vh" : (listArray?.length > 7 && screenSize.width > 575) ? "400px" : (listArray?.length <= 7 && screenSize.width > 575) ? "400px" : "auto" }}>
                                {listArray && listArray.length > 0 ?
                                    listArray.map((data, i) => {
                                        return (
                                            <Row style={{ borderBottom: managerId === data?.id ? "0.5px solid #2F86D1" : "0.5px solid #D9D9D9", paddingBottom: "5px", cursor: "pointer", background: managerId === data?.id ? "#D9EFFF" : "" }} onClick={() => handleListdetail(data)} >
                                                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} className="d-flex" style={{ paddingLeft: "20px", paddingTop: "10px", paddingBottom: "10px", }}>
                                                    <div>
                                                        <img src={data?.profilePic ? data?.profilePic : DefaultImage} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                    </div>
                                                    <div className="users-heading" >
                                                        <div className="user-main-heading">{data?.name ? data?.name : data?.email}</div>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className={data?.invite_sent ? "Invite-sent" : "listing-count"}> {data?.invite_sent ? "Invite sent" : (data?.listing_count + " Listing")}</Col>
                                            </Row>
                                        )
                                    }
                                    )
                                    :
                                    <div style={{ height: "300px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "18px", fontWeight: "500" }}>No Tenants List Found</div>
                                }
                            </div>
                        </div>
                    </Col >
                }
                <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={7}>
                    {!listDetail ?
                        screenSize.width > 575 &&
                        <div className="listing-manager-detail" >
                            <div className="lising-managed-heading">Listings managed</div>
                            <div className="manager-card">
                                <div className="select-dec">
                                    <div className="select-des-value">Select a Manager to see their details and the listing they are assign you</div>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="listing-detail-data">
                                <div className="listing-detail-icon">
                                    {screenSize.width < 1200 &&
                                        <img src={ArrowBackChat} alt="back" style={{ cursor: "pointer", marginLeft: "10px" }} onClick={handleArrowchange} />
                                    }
                                    <img src={shareIcon} alt="" />
                                </div>
                                <div className="d-flex justify-content-center listing-data" >
                                    <img src={listingDetails?.profilePic?.file ? listingDetails?.profilePic?.file : DefaultImage} alt="" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                </div>
                                <div className="d-flex justify-content-center ">
                                    <div className="listing-data-heading">{listingDetails?.name}</div>
                                </div>
                                <div className="phone-container" style={{ marginTop: "13px" }}>
                                    <div>
                                        <img src={PhoneIcon} alt="" />
                                    </div>
                                    <div className="phone-number">{listingDetails?.contact_number}</div>
                                    <div><hr style={{ border: "1px solid black", height: "20px", marginLeft: "10px", marginTop: "2px" }} /></div>
                                    <div style={{ marginLeft: "10px" }}>
                                        <img src={Mail} alt="" />
                                    </div>
                                    <div className="phone-number">{listingDetails?.email}</div>
                                </div>
                                <div className="email-conatiner mt-2">
                                    <div>
                                        <img src={PhoneIcon} alt="" className='phone-image' />
                                    </div>
                                    <div className="phone-number">{listingDetails?.contact_number}</div>
                                </div>
                                <div className="email-conatiner mt-2">
                                    <div style={{ marginLeft: "10px" }}>
                                        <img src={Mail} alt="" className='phone-image' />
                                    </div>
                                    <div className="phone-number">{listingDetails?.email}</div>
                                </div>
                                <div className="d-flex justify-content-center" style={{ marginTop: "15px" }}>
                                    <div className="msg-box" style={{ background: "#D9EFFF", color: "#2F86D1", fontSize: "16px", fontWeight: 500 }}>Manager Added</div>
                                </div>

                            </div>
                            <div className="responsive-find-list">
                                <ListingManagerDetail mangerid={managerId} data={listingDetails} contactModal={contactModal} mobileModal={mobileModal} handleImageListModal={(val) => handleImageListModal(val)} />
                            </div>
                            {/* <ListingManagerDetail mangerid={managerId} /> */}
                        </div>
                    }

                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={7}>
                    {listDetail &&
                        <div className="responsive-find-main">
                            <ListingManagerDetail mangerid={managerId} data={listingDetails} contactModal={contactModal} mobileModal={mobileModal} handleImageListModal={(val) => handleImageListModal(val)}  />
                        </div>

                    }
                </Col>
            </Row>
        </div>
    )
}

export default ListingManagerList
