import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import FavoriteSelect from '../FavoriteSelect/FavoriteSelect';
import './FavoriteAmenitie.scss'
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import url from "../../../../utils/services/urls.json"
import { navigateLogin } from '../../../../utils/services/common';
import ContinueButton from '../../../../Components/Buttons/ContinueButton/ContinueButton';
import Modal from "react-modal";
import { TextField } from '@mui/material';

const ContainerSection = styled.div`
font-family: Proxima Nova;
overflow-x: hidden;
`
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "700px",
        height: "230px",
    },
};

const FavoriteAmenities = () => {
    const navigate = useNavigate()
    const param = useParams()
    const [amenitieType, setAmenitieType] = useState([]);
    const [amenitieArrValue, setAmenitieArrValue] = useState([]);
    const [openModal, setOpenModal] = useState(false)
    const [isOtherAmenities, setIsOtherAmenities] = useState(false)
    const [otherAmenities, setOtherAmenities] = useState(localStorage.getItem("otheramenities") !== null ? localStorage.getItem("otheramenities") : '')
    const [activeStep, setActiveStep] = useState(0)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        getAmeniteValue()
    }, [])

    const getAmeniteValue = async () => {
        await getMethodWithToken(url.selectList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                response?.data.map((item) => {
                    if (item?.[1] === "amenetiy_type") {
                        setAmenitieType(item?.[0]);
                    }
                })
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const handleSelectAmenitie = (value) => {
        let arr = [...amenitieArrValue]
        if (arr.includes(value.id)) {
            let index = arr.findIndex(item => item == value.id)
            arr.splice(index, 1)
            setAmenitieArrValue(arr)
        } else {
            arr.push(value.id)
            setAmenitieArrValue(arr)
        }
    }

    useEffect(() => {
        getSteps()
    }, [])

    const getSteps = async () => {
        await getMethodWithToken(url.selectlist + param.id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                const allDetailStepFour = response?.data?.detail?.stepFour;
                const newIds = allDetailStepFour.map(value => value.id);
                setAmenitieArrValue(prevState => [...prevState, ...newIds]);
                setActiveStep(response?.data?.detail?.stepsCompleted)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleOtherAmenities = (e) => {
        setOtherAmenities(e.target.value)
        if (otherAmenities == "") {
            setIsOtherAmenities(false)
        }
        else {
            setIsOtherAmenities(true)
        }
        localStorage.setItem("otheramenities", e.target.value)
    }
    const handleOtherAmenitiesSubmit = async () => {
        let body = {
            "name": otherAmenities
        }
        await postMethodWithToken(url.otheramenities + param.id + "/", body).then((response) => {
            if (response.status === 200 || response.status === 201) {

            }
        })
    }

    const handleAmenitieSubmit = async () => {

        let body = {
            "selectedAmenetiy": amenitieArrValue
        }
        await postMethodWithToken(url.postAmenitie + param.id + "/", body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                if (isOtherAmenities) {
                    handleOtherAmenitiesSubmit()
                }
                navigate(`/Dashboard/FavoritePropertieList/upload/images/${response.data.propertyId}`)
            }
        })
    }

    const handleSaveExit = async () => {
        // let body = {
        //     "selectedAmenetiy": amenitieArrValue
        // }
        // await postMethodWithToken(url.postAmenitie + param.id + "/", body).then((response) => {
        //     if (response.status === 200 || response.status === 201) {
        //         navigate("/Dashboard/homePage")
        //     }
        // })
        navigate("/Dashboard/homePage")
    }

    useEffect(() => {
        if (openModal) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, [openModal]);

    
    const handleOpenBack = () => {
        setOpenModal(true)
    }
    const handleCloseBack = () => {
        setOpenModal(false)
    }

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <ContainerSection>
                <Row style={{ margin: "0" }} >
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <FavoriteSelect activeStep={activeStep} value={3} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={7}>
                        <div className="amenitie-container" >
                            <div className="amenitie-heading " >What amenities does your place offer?</div>
                            <div className="row amenite-description" >
                                <div className="amenitie-description-value" >You can add more amenitie or make changes after the listing get published.</div>
                            </div>
                        </div>
                        <div className="amenitie-section">
                            {amenitieType && amenitieType.length > 0 && amenitieType.map((item, index) => {
                                return (
                                    <>
                                        <div className="amenitie-box" style={{ border: amenitieArrValue.includes(item?.id) ? "2px solid #2F86D1" : "2px solid #505050" }} onClick={() => handleSelectAmenitie(item)}  >
                                            <div className="amenitie-select d-flex">
                                                <div >
                                                    <img src={item?.image} alt="" className='amenitie-image' />
                                                </div>
                                                <div className="amenitie-select-heading" style={{ color: amenitieArrValue.includes(item?.id) ? "#2F86D1" : "#505050" }} >{item?.name}</div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                        <div className='otheramenities'>
                            <TextField id="standard-basic" label="Add Other Amenities" variant="standard" style={{ marginTop: '10px', fontSize: '20px' }} value={otherAmenities} onChange={handleOtherAmenities} />
                        </div>

                    </Col>
                    <Col>
                        <div className="d-md-flex d-none buttons" style={{ margin: "20px 0 20px 30px", alignItems: "center" }} >
                            <div className="backButton-amite">
                                <ContinueButton className="back-list" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={handleOpenBack} fontSize={"20px"} fontWeight={"400"} />
                            </div>
                            <div className="d-flex justify-content-end">
                                <div style={{ paddingRight: "50px", display: "flex", alignItems: "center" }} className='saveButton'>
                                    <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                                <div className='saveButton-amite'>
                                    <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleAmenitieSubmit()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                            </div>
                        </div>
                        <div className="d-md-none d-flex justify-content-between mt-3 mb-3">
                            <div style={{ display: "flex", alignItems: "center" }} className='saveExit-mobile-amite'>
                                <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={screenWidth < 768 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} />
                            </div>
                            <div className='saveButton-mobile-amite'>
                                <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleAmenitieSubmit()} width={screenWidth < 768 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </ContainerSection>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="d-flex justify-content-center">
                    <div className="back-heading">Are you sure you want to back?</div>
                </div>
                <div className="d-flex justify-content-center" >
                    <div style={{ marginTop: "10px" }}>All the changes you will made will be discarded</div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex" style={{ marginTop: "50px" }}>
                        <div className="stay-heading" onClick={handleCloseBack}>Stay on this page</div>
                        <div>
                            <ContinueButton text="Go back" color={'#fff'} onClick={() => navigate(-1)} width={"210px"} borderRadius={"4px"} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default FavoriteAmenities
