import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { getMethod, getMethodWithToken, postMethod, postMethodWithToken } from '../../../utils/services/apis';
import url from "../../../utils/services/urls.json"
import DefaultImage from "../../../assets/images/profileimage.png"
import { getParam, getToken } from "../../../utils/services/common"
import { Token } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Modal from "react-modal"
import "./Navbar.scss"
import { Col, Row } from 'react-bootstrap';
import ContinueButton from '../../Buttons/ContinueButton/ContinueButton';
import { toast } from 'react-toastify';
import "../../../screens/LandingPage/HeadingSection/HeadingSection.scss"

const StyledNavBar = styled.div`
    background: #fff;
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 3px 12px 0px #0000001F;
    position: fixed;
    top:0;
    justify-content: space-between;
    // display: flex;
    align-items: center;
    width: 100vw;
    // Z-index:3;

`;
const StyledNavBarVirtual = styled.div`
    background: red;
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 3px 12px 0px #0000001F;
    // position: fixed;
    top:0;
    justify-content: space-between;
    // display: flex;
    align-items: center;
    width: 100vw;
    // Z-index: 3;
    visibility:hidden;
   

`;
const UlofNav = styled.ul`
font-family: Proxima_nova_medium;
font-size:'16px';
font-weight:600;
list-style: none;
padding: 0;
margin: 0;
// display: flex;
cursor: pointer;


`;
const StyledLi = styled.li`
   margin:0 20px 0 20px;

   @media only screen and (max-width: 1110px) {
    margin:0 10px 0 10px;
  }
`;
const defaultMethod = () => {

}

const Navbar = ({ scrollToSection = defaultMethod, getOpenModal = defaultMethod, getFilterModal = defaultMethod, handleToggleButton = defaultMethod, imageListModal, handleToggleContact = defaultMethod }) => {
    const navbarRef = useRef(null);
    const location = useLocation()
    const navigate = useNavigate()
    const param = useParams()
    const [navbarExpand, setNavbarExpand] = useState(false)
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const handleOutsideClick = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
            const navbarToggler = document.querySelector('.navbar-toggler');
            const navbarCollapse = document.querySelector('.navbar-collapse.show');

            if (navbarCollapse) {
                navbarToggler.click();  // This will trigger the Bootstrap collapse
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const styleWithRelist = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-50%, -50%)',
            width: screenSize.width <= 575 ? "80vw" : screenSize.width <= 800 ? "50vw" : screenSize.width <= 900 ? "50vw" : screenSize.width <= 950 ? "50vw" : screenSize.width <= 1100 ? "40vw" : "35vw",
            height: screenSize.width <= 575 ? "72vh" : screenSize.width <= 800 ? "60vh" : screenSize.width <= 900 ? "53vh" : screenSize.width <= 950 ? "46vh" : screenSize.width <= 1100 ? "42vh" : "60vh",
            padding: "0px",
        },
    }
    const [profileName, setProfileName] = useState("")
    const [profileImage, setProfileImage] = useState([])
    const [openRentModal, setOpenRentModal] = useState(false)
    const [continueButton, setcontinueButton] = useState(false);
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [message, setMessage] = useState("")



    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value.trimStart())
    }
    const handleLastNameChange = (event) => {
        setLastName(event.target.value.trimStart())
    }
    const handleEmailChange = (event) => {
        setEmailAddress(event.target.value.trimStart())
    }
    const handleMessageChange = (event) => {
        setMessage(event.target.value.trimStart())
    }

    const handleOpenModal = () => {
        setOpenRentModal(true)
        setNavbarExpand(true)
        setFirstName("")
        setLastName("")
        setEmailAddress("")
        setMessage("")
        getOpenModal(true)
        getFilterModal(true)
        handleToggleContact(false)
    }
    const closeModal = () => {
        setOpenRentModal(false)
        setNavbarExpand(false)
        getOpenModal(false)
        getFilterModal(false)
    }

    useEffect(() => {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)) {
            setcontinueButton(true)
        } else {
            setcontinueButton(false)
        }
    }, [emailAddress])

    useEffect(() => {
        getProfile()
        getToken(Token)
        getParam(param)
    }, [])

    const getProfile = async () => {
        await getMethodWithToken(url.userProfile).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setProfileName(response.data.name && response?.data?.name)
                setProfileImage(response?.data?.profilePic)
                localStorage.setItem("userId", response?.data?.id)
                localStorage.setItem("userName", response?.data?.name)
                localStorage.setItem("userEmail", response?.data?.email)
                localStorage.setItem("subscriptionData", JSON.stringify(response?.data?.subscription_detail))
            }
        })
    }

    const handlePostContact = async () => {
        let body = {
            "first_name": firstName,
            "last_name": lastName,
            "email": emailAddress,
            "message": message
        }
        if (firstName !== "" && lastName !== "" && emailAddress !== "" && message !== "") {
            if (continueButton) {
                await postMethod(url.postContact, body).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        toast.success(response?.data?.success)
                        setOpenRentModal(false)
                    }
                })
            }
        } else {
            toast.error("Please Fill All Field")

        }
    }
    const handleProfileChange = () => {
        navigate("/Dashboard/userProfile")

        if (screenSize.width < 575) {
            setNavbarExpand(!navbarExpand)

        }
    }
    const handleListChange = () => {
        navigate("/Dashboard/FavoritePropertieList")
        setAboutValue(false)

    }
    const handleHomePage = () => {
        navigate("/Dashboard/homePage")
        setAboutValue(false)
        setNavbarExpand(!navbarExpand)
    }
    const handleChatChage = () => {
        navigate("/Dashboard/Chats")
    }

    const [aboutValue, setAboutValue] = useState(false)

    const handleAboutNavigate = () => {
        if (screenSize.width < 575) {
            setNavbarExpand(!navbarExpand)

        }

        if (location?.pathname === "/Dashboard/homePage") {
            scrollToSection('about');
            setAboutValue(true)
        } else {
            navigate("/Dashboard/homePage");
            setAboutValue(true)

            setTimeout(() => {
                window.scrollTo({
                    top: document.body.scrollHeight + 2000,
                    behavior: 'smooth' // for smooth scrolling
                });
            }, 200)


        }
    }

    // useEffect(() => {
    //     if (aboutValue === true) {
    //         setTimeout(() => {
    //             scrollToSection('about');
    //         }, 100)
    //     }
    // }, [aboutValue === true])

    // useEffect(() => {
    //     if (openRentModal) {
    //         document.body.style.overflowY = 'hidden';
    //     } else {
    //         document.body.style.overflowY = 'auto';
    //     }
    //     return () => {
    //         document.body.style.overflowY = 'auto';
    //     };
    // }, [openRentModal]);

    useEffect(() => {
        if (openRentModal) {
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
            }, 0);
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [openRentModal]);

    const handleFindPeople = () => {
        navigate("/Dashboard/FindPeople")
    }
    const handleLogout = async () => {
        const paramValue = getParam();

        await postMethodWithToken(url.logout, getToken(Token)).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/${paramValue}/Login`, {});
                localStorage.removeItem("token")
                localStorage.removeItem("userId")
                localStorage.removeItem("subscriptionData")
                localStorage.removeItem("userEmail")
                localStorage.removeItem("PropertyType")
                localStorage.removeItem("name")
                localStorage.removeItem("bedroom");
                localStorage.removeItem("bathroom")
                localStorage.removeItem("balcony")
                localStorage.removeItem("propertysize")
                localStorage.removeItem("furnishingid");
                localStorage.removeItem("furnishing");
                localStorage.removeItem("parkingname")
                localStorage.removeItem("parkingid")
                localStorage.removeItem("floorno")
                localStorage.removeItem("floorid")
                localStorage.removeItem("totalfloor")
                localStorage.removeItem("totalfloorid")
                localStorage.removeItem("storiesName")
                localStorage.removeItem("storiesId")
                localStorage.removeItem("petid")
                localStorage.removeItem("otherpet")
                localStorage.removeItem("locality")
                localStorage.removeItem("city")
                localStorage.removeItem("state")
                localStorage.removeItem("zipcode")
                localStorage.removeItem("latitude")
                localStorage.removeItem("latitude")
                localStorage.removeItem("direction")
                localStorage.removeItem("amenities");
                localStorage.removeItem("imageID")
                localStorage.removeItem("images")
                localStorage.removeItem("subscriptionbutton")
                localStorage.removeItem("masterSubscrption")
                localStorage.removeItem("date");
                localStorage.removeItem("inputvalue")
                localStorage.removeItem("checkpoint");
                localStorage.removeItem("showorganization");
                localStorage.removeItem("subscriptionType");
                localStorage.removeItem("subscriptionId");
                localStorage.removeItem("depositAmount");
                localStorage.removeItem("subscriptionPriceValue")
                localStorage.removeItem("userName")
                localStorage.removeItem("availabilityValue");
                localStorage.removeItem("addComment");
                localStorage.removeItem("findListTab");
                localStorage.removeItem("myListTab")

            }
        })
    }

    const getLastWordWithCapital = (sentence) => {
        const words = sentence.trim().split(" ");
        if (words?.length > 1) {
            const lastWord = words[words.length - 1];
            const capitalizedLastWord = lastWord.charAt(0).toUpperCase();
            words[words.length - 1] = capitalizedLastWord + ".";
        }
        return words.join(" ");
    }

    const capitalizeEachWord = (sentence) => {
        const words = sentence.trim().split(" ");
        const capitalizedWords = words.map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
        );
        return capitalizedWords.join(" ");
    }

    const [toggleButton, setToggleButton] = useState(false)

    const handleNavbar = () => {
        setNavbarExpand(!navbarExpand)
        setToggleButton(!toggleButton)
        handleToggleButton(!toggleButton)
    }
    return (
        <>
            <StyledNavBarVirtual style={{ display: (navbarExpand || location.pathname !== "/Dashboard/homePage") ? "none" : "", zIndex: imageListModal ? "0" : "3" }}>
                <nav class="navbar navbar-expand-lg bg-body-tertiary" ref={navbarRef}>
                    <div class="container-fluid">
                        <div onClick={() => handleHomePage()}><img src={require('../../../assets/images/GovHomeLogo.png')} alt="" style={{ maxWidth: '40px', cursor: "pointer" }} className='mobile-logo' /></div>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={toggleButton} aria-label="Toggle navigation" onClick={handleNavbar}>
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="nav-my-value d-flex flex-wrap">
                                <ul className="navbar-nav mb-2 mb-lg-0" style={{ fontSize: "16px", fontWeight: 600, color: "#000000" }}>
                                    <li className="nav-item">
                                        <div className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-current="page" style={{ cursor: "pointer" }} onClick={() => handleHomePage()}>Explore Nearby Properties</div>
                                    </li>
                                    <li className="nav-item">
                                        <div className="nav-link" style={{ cursor: "pointer" }} onClick={() => handleListChange()}>List your Property</div>
                                    </li>
                                    <li className="nav-item">
                                        <div className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" style={{ cursor: "pointer" }} onClick={() => handleFindPeople()}>Find People</div>
                                    </li>
                                    <li className="nav-item">
                                        <div className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" style={{ cursor: "pointer" }} onClick={() => handleOpenModal()}>Contact Us</div>
                                    </li>
                                    <li className="nav-item">
                                        <div className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" style={{ cursor: "pointer" }} onClick={() => handleAboutNavigate("AboutUs")}>About Us</div>
                                    </li>
                                    <div className="d-flex align-items-center justify-content-end flex-wrap d-block d-lg-none">
                                        <div className="nav-link" href="#">
                                            <MessageOutlinedIcon data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" style={{ marginRight: '30px', cursor: "pointer" }} onClick={() => handleChatChage()} />
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <img data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" src={profileImage !== null ? profileImage?.file : DefaultImage} alt="" style={{ width: '40px', height: '40px', borderRadius: '50%', cursor: "pointer" }} onClick={() => handleProfileChange()} />
                                            <div className="dropdown-center align-items-center d-flex" onClick={() => handleProfileChange()}>
                                                <buton className="border-0 p-0 ms-2" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" style={{ color: '#0075CA', marginTop: "-10px" }} type="button">
                                                    {profileName}
                                                </buton>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-block d-lg-none">
                                        <li className="nav-item">
                                            <div className="nav-link" onClick={handleLogout} style={{ cursor: "pointer" }}>Logout</div>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end flex-wrap d-none d-lg-flex">
                            <div className="nav-link" href="#">
                                <MessageOutlinedIcon style={{ marginRight: '30px', cursor: "pointer" }} onClick={() => handleChatChage()} />
                            </div>
                            <div className='d-flex align-items-center'>
                                <img src={profileImage !== null ? profileImage?.file : DefaultImage} alt="" style={{ width: '30px', height: '30px', borderRadius: '50%', cursor: "pointer" }} onClick={() => handleProfileChange()} />
                                <div className="dropdown-center align-items-center d-flex">
                                    <button className="btn dropdown-toggle border-0 p-0 ms-2" style={{ color: '#0075CA', width: "135px" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {profileName}
                                    </button>
                                    <ul className="dropdown-menu" style={{ left: '-51px' }}>
                                        <li><a className="dropdown-item" onClick={() => handleProfileChange()} style={{ cursor: "pointer", color: location.pathname === "/Dashboard/userProfile" ? "#0075CA" : "black" }}>Profile</a></li>
                                        <li><a className="dropdown-item" onClick={handleLogout} style={{ cursor: "pointer" }}>Logout</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </nav>
            </StyledNavBarVirtual>
            <StyledNavBar style={{ zIndex: imageListModal ? "0" : "3" }}>
                <nav class="navbar navbar-expand-lg bg-body-tertiary" ref={navbarRef}>
                    <div class="container-fluid">
                        <div onClick={() => handleHomePage()}><img src={require('../../../assets/images/GovHomeLogo.png')} alt="" style={{ maxWidth: '40px', cursor: "pointer" }} className='mobile-logo' /></div>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={toggleButton} aria-label="Toggle navigation" onClick={handleNavbar}>
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="nav-my-value d-flex flex-wrap">
                                <ul className="navbar-nav mb-2 mb-lg-0" style={{ fontSize: "16px", fontWeight: 600, color: "#000000" }}>
                                    <li className="nav-item">
                                        <div className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-current="page" style={{ cursor: "pointer" }} onClick={() => handleHomePage()}>Explore Nearby Properties</div>
                                    </li>
                                    <li className="nav-item">
                                        <div className="nav-link" style={{ cursor: "pointer" }} onClick={() => handleListChange()}>List your Property</div>
                                    </li>
                                    <li className="nav-item">
                                        <div className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" style={{ cursor: "pointer" }} onClick={() => handleFindPeople()}>Find People</div>
                                    </li>
                                    <li className="nav-item">
                                        <div className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" style={{ cursor: "pointer" }} onClick={() => handleOpenModal()}>Contact Us</div>
                                    </li>
                                    <li className="nav-item">
                                        <div className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" style={{ cursor: "pointer" }} onClick={() => handleAboutNavigate("AboutUs")}>About Us</div>
                                    </li>
                                    <div className="d-flex align-items-center justify-content-end flex-wrap d-block d-lg-none">
                                        <div className="nav-link" href="#">
                                            <MessageOutlinedIcon data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" style={{ marginRight: '30px', cursor: "pointer" }} onClick={() => handleChatChage()} />
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <img data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" src={profileImage !== null ? profileImage?.file : DefaultImage} alt="" style={{ width: '40px', height: '40px', borderRadius: '50%', cursor: "pointer" }} onClick={() => handleProfileChange()} />
                                            <div className="dropdown-center align-items-center d-flex" onClick={() => handleProfileChange()}>
                                                <buton className="border-0 p-0 ms-2" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" style={{ color: '#0075CA', marginTop: "-10px" }} type="button">
                                                    {profileName?.length > 0 && capitalizeEachWord(getLastWordWithCapital(profileName))}
                                                </buton>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-block d-lg-none">
                                        <li className="nav-item">
                                            <div className="nav-link" onClick={handleLogout} style={{ cursor: "pointer" }}>Logout</div>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end flex-wrap d-none d-lg-flex">
                            <div className="nav-link" href="#">
                                <MessageOutlinedIcon style={{ marginRight: '30px', cursor: "pointer" }} onClick={() => handleChatChage()} />
                            </div>
                            <div className='d-flex align-items-center'>
                                <img src={profileImage !== null ? profileImage?.file : DefaultImage} alt="" style={{ width: '30px', height: '30px', borderRadius: '50%', cursor: "pointer" }} onClick={() => handleProfileChange()} />
                                <div className="dropdown-center align-items-center d-flex">
                                    <button className="btn dropdown-toggle border-0 p-0 ms-2" style={{ color: '#0075CA' }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {profileName?.length > 0 && capitalizeEachWord(getLastWordWithCapital(profileName))}
                                    </button>
                                    <ul className="dropdown-menu" style={{ left: '-51px' }}>
                                        <li><a className="dropdown-item" onClick={() => handleProfileChange()} style={{ cursor: "pointer", color: location.pathname === "/Dashboard/userProfile" ? "#0075CA" : "black" }}>Profile</a></li>
                                        <li><a className="dropdown-item" onClick={handleLogout} style={{ cursor: "pointer" }}>Logout</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </nav>
            </StyledNavBar>
            <Modal isOpen={openRentModal} style={styleWithRelist} overlayClassName="myoverlay">
                <div className="contact-modal ">
                    <div className="contact-heading">Contact Detail</div>
                    <Row style={{ padding: "15px 20px 0 20px" }}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <div className="message-heading">First Name</div>
                            <TextField
                                fullWidth
                                required
                                value={firstName}
                                onChange={(e) => handleFirstNameChange(e)}
                                id="outlined-required"
                            />

                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <div className="message-heading">Last Name</div>
                            <TextField
                                fullWidth
                                required
                                value={lastName}
                                onChange={(e) => handleLastNameChange(e)}
                                id="outlined-required"
                            />
                        </Col>
                        <Col lg={12}>
                            <div className="message-heading" style={{ marginTop: "20px" }}>Email Address</div>
                            <TextField
                                fullWidth
                                required
                                value={emailAddress}
                                onChange={(e) => handleEmailChange(e)}
                                id="outlined-required"
                            />
                            {(!continueButton && emailAddress !== "") &&
                                <div className="email-error">Please Enter a Vaild Email</div>
                            }
                        </Col>
                        <Col lg={12}>
                            <div className="message-heading" style={{ marginTop: "15px" }}>Message</div>
                            <textarea name="postContent" style={{ width: '100%', border: "1px solid black" }} value={message} onChange={(e) => handleMessageChange(e)} rows="5" />
                        </Col>
                        <Col lg={6} style={{ marginTop: "15px" }}>
                            <ContinueButton text={'Cancel'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontWeight={500} width={"100%"} onClick={() => closeModal()} />
                        </Col>
                        <Col lg={6} style={{ marginTop: "15px" }}>
                            <ContinueButton text={'Save'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'100%'} borderRadius={"4px"} fontWeight={600} onClick={() => handlePostContact()} />
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
}

export default Navbar
