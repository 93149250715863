import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import "../TenantManagerTabDetail/TenantManagerTabDetail.scss"
import { Col, Row } from 'react-bootstrap';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getMethodWithToken } from '../../../utils/services/apis';
import url from '../../../utils/services/urls.json';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Bedroom from "../../../assets/images/listingManager/bedroom.svg"
import Bathroom from "../../../assets/images/listingManager/bathroom.svg"
import PropertySize from "../../../assets/images/listingManager/square_foot.svg"
import Parking from "../../../assets/images/listingManager/parking.svg"
import Clock from "../../../assets/images/listingManager/clock.svg"
import Home from "../../../assets/images/listingManager/home.svg"
import Furnishing from "../../../assets/images/listingManager/furnishing.svg"
import ArrowForward from "../../../assets/images/favoriteProperty/arrowForward.svg"
import moment from 'moment';
import Modal from "react-modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import prevArrowImage from '../../../assets/images/searchproperty/backwardarrow.svg';
import nextArrowImage from '../../../assets/images/searchproperty/forwardarrow.svg';
import Cross from '../../../assets/images/favoriteProperty/cross.svg';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ListingTab = styled.div`
font-family: Proxima_nova_reg;
height: auto;
margin-bottom:25px ;
background: white;
padding: 10px 10px 50px 10px;
box-shadow: 0px 2px 8px 0px #0000001F;
`;

const CustomImage = styled.img`
background:url('${props => props?.image}');
height: 140px;
width:100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;
cursor: pointer;

@media (min-width:915px) and (max-width: 1199px) {
     height: 162px;
   }

   @media (min-width: 820px) and (max-width: 915px) {
     height: 162px;
   }
      
 @media (min-width:575px) and  (max-width:768px) {
   height: 142px;
  }

   @media (min-width:431px) and  (max-width:575px) {
   height: 320px;
  }

   @media  (min-width:400px) and  (max-width:431px) {
   height: 220px;
  }

   @media (max-width:400px) {
   height: 190px;
  }

`
const CustomImageModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  
  img {
    max-width: 100%;     
    max-height: 100%;    
    object-fit: contain;  
  }
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    rootValue: {
        maxWidth: 400,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        // height: 210,
        display: 'block',
        // maxWidth: 300,
        overflow: 'hidden',
        width: '100%',
    },
}));

const TenantManagerDetail = ({ tenantId, data, handleImageListModal }) => {
    const navigate = useNavigate()
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [value, setValue] = React.useState(0);
    const [propertyDetail, setPropertyDetail] = useState([]);
    const [propertyImage, setPropertyImage] = useState([]);
    const [tenantDetail, setTenantDetail] = useState([]);
    const [paymentList, setPaymentList] = useState([])
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStylesWithImage = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: screenSize.width <= 800 ? "100vw" : "100vw",
            height: screenSize.height <= 800 ? "100vh" : "100vh",
            maxHeight: "100vh",
            padding: '0px',
            overflow: 'hidden',
            position: "relative",
            backgroundColor: screenSize.width < 1030 ? "#000000" : "transparent",
            border: "none"
        },
    };


    const getPaymentList = async () => {
        await getMethodWithToken(url.getPastTransaction).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setPaymentList(response?.data)
            }
        })
    }


    const handleNext = (data, galleryIndex) => {

        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === data?.property_images?.length - 1
                ? 0
                : (prevSteps[data?.propertyMaster_id] || 0) + 1
        }));
    };

    const handleBack = (data, galleryIndex) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === 0
                ? data?.property_images?.length - 1
                : (prevSteps[data?.propertyMaster_id] || data?.property_images?.length) - 1
        }))

    };

    const handleStepChange = (data, step) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: step
        }));
    };

    const getRandomNumberProperty = (number) => {
        return Math.floor(Math.random() * number);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 1) {
            getPaymentList()
        }
    };

    useEffect(() => {
        if (data) {
            setTenantDetail(data?.subscription_details)
            setPropertyDetail(data?.property_details)
            let array = [...propertyImage]
            data?.property_details?.[0]?.property_images?.length > 0 &&
                array.push(data?.property_details?.[0]?.property_images)
            setPropertyImage(array)
        }
    }, [data])

    const maxStepsMyListings = propertyImage?.[0]?.length;

    const handleSearchDetail = (propertyId) => {
        navigate(`/Dashboard/SearchList/SearchDetail/${propertyId}`)
    }

    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    const [detailImage, setDetailImage] = useState("")
    const [imageModal, setImageModal] = useState(false)
    const [indexModal, setIndexModal] = useState("")

    const handleImageModal = (id, index) => {
        setDetailImage(id)
        setImageModal(true)
        setIndexModal(index)
        handleImageListModal(true)
    }
    const handleCloseImageModal = () => {
        setImageModal(false)
        handleImageListModal(false)
    }

    const CustomPrevArrow = (props) => {
        const { disabled, onClick } = props;
        return (
            <div
                className="custom-image-upload prev-arrows-image"
                onClick={(e) => {
                    e.stopPropagation(); // Stop event propagation
                    if (!disabled && onClick) onClick();
                }}
            >
                <img src={prevArrowImage} alt="Previous" />
            </div>
        );
    };

    const CustomNextArrow = (props) => {
        const { disabled, onClick } = props;
        return (
            <div
                className="custom-image-upload next-arrows-image "
                onClick={(e) => {
                    e.stopPropagation(); // Stop event propagation
                    if (!disabled && onClick) onClick();
                }}
            >
                <img src={nextArrowImage} alt="Next" />
            </div>
        );
    }
    const [currentSlide, setCurrentSlide] = useState(0);
    var settings = {
        infinite: true,
        dots: true,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        autoplaySpeed: 2000,
        beforeChange: (current, next) => setCurrentSlide(next),
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        initialSlide: +detailImage,
        appendDots: (dots) => (
            <div onClick={(e) => e.stopPropagation()}>{dots}</div>
        )
    };

    useEffect(() => {
        if (imageModal) {
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
            }, 0);
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [imageModal]);

    return (
        <>
            <Row >
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <div style={{ fontSize: "16px", fontWeight: 500, marginTop: "15px" }}>Tenant Details</div>
                </Col>
            </Row>
            <div className="tenant-manager-tab">
                <div style={{ marginTop: "15px" }}>
                    <Tabs value={value} onChange={handleChange}  >
                        <Tab label="Tenant Details" {...a11yProps(0)} style={{ textTransform: 'capitalize' }} />
                        <Tab label="Past Transactions" {...a11yProps(1)} style={{ textTransform: 'capitalize' }} />
                    </Tabs>
                </div>
            </div>
            <div className="tenant-list-tab-card" style={{ boxShadow: "0px 2px 8px 0px #0000001F" }}>
                <TabPanel value={value} index={0}>
                    <div className="tenant-tab-card">
                        {tenantDetail?.length > 0 && tenantDetail.map((data, index) =>
                            <>
                                <Row style={{ margin: "0", padding: "0" }} >
                                    <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} style={{ padding: '10px', }}>
                                        <div className='tenant_detail'>Rent Amount</div>
                                        <div style={{ fontSize: '32px', fontWeight: '700' }}>${data?.price} <span style={{ fontSize: '16px', color: '#919CA5' }}>/{data?.per_month === false ? 'day' : 'month'}</span></div>
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} style={{ padding: '10px', }}>
                                        <div className='tenant-detail-payments'>Next Payment</div>
                                        <div className='date'>{moment(data?.next_payment).format('D MMMM, YYYY')}</div>
                                    </Col>
                                </Row>
                                <Row style={{ padding: screenSize.width <= 575 ? "10px 10px 32px 10px" : '10px 10px 48px 10px', }} >
                                    <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                                        <div className='tenant-detail-payments'>Tenant since</div>
                                        <div className='date'>{moment(data.created_at).format('D MMMM, YYYY')}</div>
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                                        <div className='tenant-detail-payments pt-3 pt-sm-0'>Till</div>
                                        <div className='date'>{moment(data.till).format('D MMMM, YYYY')}</div>
                                    </Col>
                                </Row>
                            </>

                        )}
                        <div style={{ fontWeight: '600', fontSize: '16px', fontFamily: 'Proxima_nova_medium', padding: screenSize.width <= 575 ? "10px 10px 0px 10px " : '10px 10px 10px 0', }}>Property details</div>
                        <hr></hr>

                        {propertyDetail && propertyDetail.length > 0 ? propertyDetail.map((data, index) =>
                            <Row >
                                <Col xs={12} sm={12} md={5} lg={4} xl={4} xxl={4}>
                                    <div>
                                        <SwipeableViews
                                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                            index={activeStep[data?.propertyMaster_id] || 0}
                                            onChangeIndex={(step) => handleStepChange(data, step)}
                                            enableMouseEvents
                                        >
                                            {data?.property_images.map((step, ind) => {
                                                return (
                                                    <>
                                                        <div key={ind}>
                                                            <CustomImage image={step?.media?.file} alt={step.label} onClick={() => handleImageModal(ind, index)} />
                                                        </div>

                                                    </>
                                                )
                                            })}
                                        </SwipeableViews>
                                        <MobileStepper
                                            position="static"
                                            variant="text"
                                            nextButton={
                                                <Button size="small" style={{ color: "white" }} onClick={() => handleNext(data, index)}>

                                                    {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                </Button>
                                            }
                                            backButton={
                                                <Button size="small" style={{ color: "white" }} onClick={() => handleBack(data, index)}>
                                                    {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                </Button>
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={7} lg={8} xl={8} xxl={8}>
                                    <div className="listing-card-detail" >
                                        <div className="d-flex justify-content-between mobile-subscription" >
                                            <div className="d-flex">
                                                <div className="listing-user-heading" > ${data?.subscription_details?.price}/</div>
                                                <div className="listing-user-month">{data?.subscription_details?.per_day ? "day" : "month"}</div>
                                            </div>

                                            <div class="d-flex d-sm-none align-items-center justify-content-end">
                                                <div className="d-flex align-items-center" onClick={() => handleSearchDetail(data?.propertyMaster_id)} style={{ marginLeft: "30px", cursor: "pointer" }}>
                                                    <div className="view-heading">View Details</div>
                                                    <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                </div>
                                            </div>

                                        </div>

                                        <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}>{data?.apartmentName}{data?.location.locality} </div>
                                        <Row style={{ marginTop: "10px", borderBottom: "1px solid #B7B7B7", paddingBottom: "5px" }}>
                                            <Col xs={5} sm={5} md={6} className='d-flex'>
                                                <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                <div className="categroy-heading-type">Bedroom</div>
                                                <div className="category-value">{data?.bedroomCount}</div>
                                            </Col>
                                            <Col xs={7} sm={7} md={6} className='d-flex'>
                                                <div  ><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                <div className="categroy-heading-type">Bathroom</div>
                                                <div className="category-value" style={{ textOverflow: 'ellipsis' }}>{data?.propertyType?.name === "Room" ? data?.room_sharing?.name : data?.bathroomCount}</div>
                                            </Col>

                                        </Row>
                                        <div>
                                            <Row >
                                                <Col md={12} lg={12} xl={12} xxl={12} >
                                                    <div className="d-none d-sm-inline">
                                                        <div className="d-flex" style={{ marginTop: "10px" }} >
                                                            <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                            <div className="categroy-heading">Available from</div>
                                                        </div>
                                                        <div className="select-value-heading">{data?.subscription_details?.propertyAvailfrom !== null && moment(data?.subscription_details?.propertyAvailfrom).format('D MMMM, YYYY')}</div>
                                                    </div>


                                                </Col>

                                                <Col xs={5} sm={5} md={6} className="d-flex d-sm-none">
                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                        <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Available from</div>
                                                    </div>
                                                </Col>
                                                <Col xs={7} sm={7} md={6} className="d-flex d-sm-none">
                                                    <div className="select-value-heading">{data?.subscription_details?.propertyAvailfrom !== null && moment(data?.subscription_details?.propertyAvailfrom).format('D MMMM, YYYY')}</div>
                                                </Col>

                                            </Row>
                                        </div>
                                    </div>
                                </Col>

                                <div class="d-none d-sm-flex align-items-center justify-content-end">
                                    <div className="d-flex align-items-center mt-2" onClick={() => handleSearchDetail(data?.propertyMaster_id)} style={{ marginLeft: "30px", cursor: "pointer" }}>
                                        <div className="view-heading">View Details</div>
                                        <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                    </div>
                                </div>
                            </Row>

                        ) : <div className='NoMyListing' >
                            <div>
                                <div className='NoListing'>No listings assigned</div>
                                <div className='ManagingRequest'>Send Managing Request</div>
                            </div>

                        </div>}
                    </div>
                </TabPanel >
                <TabPanel value={value} index={1} style={{ background: 'white', padding: '10px', marginBottom: '10px', marginTop: '10px', height: paymentList && paymentList.length === 0 && "35vh", display: paymentList && paymentList.length === 0 && "flex", justifyContent: paymentList && paymentList.length === 0 && "center", alignItems: paymentList && paymentList.length === 0 && "center" }}>
                    {paymentList && paymentList.length > 0 ? paymentList.map((item, index) => {
                        return (
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className='date'>{moment(item?.created_at).format("DD MMMM YYYY")}</div>
                                    <div className='amount'>{item?.price}</div>
                                </div>
                                <hr style={{ color: '#919CA5' }} />
                            </div>

                        )
                    }
                    )
                        :
                        <div style={{ fontSize: "18px", fontWeight: 500 }}>No Payment Past List Found</div>
                    }

                </TabPanel>

            </div >

            <Modal isOpen={imageModal} style={customStylesWithImage} overlayClassName="myoverlay">
                <Row>
                    <Col>
                        <div className="modal-upload-image-section" onClick={(e) => {
                            if (screenSize.width > 1030 && !e.target.closest('.slick-dots')) {
                                handleCloseImageModal();
                            }
                        }}>
                            <div style={{ position: "absolute", cursor: 'pointer', top: screenSize.width < 575 ? "50px" : "10px", right: "10px", zIndex: "999" }}>
                                <img
                                    src={Cross}
                                    alt="Remove"
                                    // style={{ cursor: 'pointer' }}
                                    onClick={handleCloseImageModal}
                                />
                            </div>
                            <Slider {...settings} style={{ width: "100%", zIndex: "99" }}>
                                {propertyDetail && propertyDetail.length > 0 && propertyDetail.map((data, ind) => {
                                    return (
                                        ind === indexModal && data?.property_images.map((step, index) => {
                                            return (
                                                <>
                                                    <div key={index}>
                                                        <CustomImageModal>
                                                            <img src={step?.media?.file} alt={step?.label} />
                                                        </CustomImageModal>
                                                    </div>

                                                </>
                                            )
                                        })
                                    )
                                })}
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>

    )

}
export default TenantManagerDetail