import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import GovHome from "../../../../assets/images/GovHomeLogo.png";
import './FavoriteHome.scss';
import { Col, Row } from 'react-bootstrap';
import FavHomeImage from "../../../../assets/images/favHome.svg"
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton";
import { useNavigate } from 'react-router-dom';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MobileLogo from "../../../../assets/icons/onlyLogo.png"

const Section = styled.div`
    margin: 40px 0px 0 50px;
    font-family: Proxima Nova;
    @media (max-width:767px){
    margin:10px
    }
`
const FavoriteHome = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    const handleNext = () => {
        navigate("SelectList")
    }
    const handleHomePage = () => {
        navigate("/Dashboard/homePage")
    }

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <Section>
            <div className='d-md-block d-none'>
                <img src={GovHome} alt="" style={{ width: "100px", height: "80px", cursor: "pointer" }} onClick={() => handleHomePage()} />
            </div>
            <div className='d-md-none justify-content-between align-items-center mt-3 d-flex d-flex'>
                <ArrowBackOutlinedIcon style={{ width: '24px', height: '24px' }} onClick={() => navigate(-1)} />
                <div style={{ textAlign: 'right' }}>
                    <img src={MobileLogo} alt='' style={{ cursor: "pointer", width: '22px', height: '32px' }} onClick={() => handleHomePage()} />
                </div>
            </div>
            <Row className="main-content">
                <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="d-none d-md-block">
                    <div>
                        <div className="fav-heading font_style" >Let us know about your place</div>
                        <div className="fav-description font_style" >We’ll ask you which type of property you have and if guests will book the entire place or just a room. Then let us know the location and how many guests can stay.</div>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="list-home">
                    <img src={FavHomeImage} alt="" style={{ width: "100%", height: "100%" }} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="d-block d-md-none">
                    <div>
                        <div className="fav-heading font_style" >Let us know about your place</div>
                        <div className="fav-description font_style" >We’ll ask you which type of property you have and if guests will book the entire place or just a room. Then let us know the location and how many guests can stay.</div>
                    </div>
                </Col>
            </Row>

            <div className="d-flex justify-content-end justify-content-sm-between buttons" style={{ marginTop: "180px", marginBottom: "20px", paddingTop: screenWidth <= 575 && "112px" }}>
                <div className="back-ipaid">
                    <ContinueButton className="back-list" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={() => navigate(-1)} fontSize={"20px"} fontWeight={"400"} />
                </div>
                <div className="font_style">
                    <ContinueButton text="Get Started" color={'#fff'} onClick={() => handleNext()} width={screenWidth < 768 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} />
                </div>
            </div>
        </Section>
    )
}

export default FavoriteHome
