import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import "./FinalStep.scss";
import { Col, Row } from 'react-bootstrap';
import FormInput from '../../../../Components/Inputs/FormInput/FormInput';
import { TextField, MenuItem, FormControl, Select, InputAdornment, InputLabel } from '@mui/material';
import calender from '../../../../assets/icons/calendar_month.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import format from 'date-fns/format';
import FavoriteSelect from "../FavoriteSelect/FavoriteSelect";
import { useParams, useNavigate } from 'react-router-dom';
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton";
import url from "../../../../utils/services/urls.json";
import { postMethodWithToken, getMethodWithToken } from '../../../../utils/services/apis';
import { toast } from 'react-toastify';
import Modal from "react-modal"
import { navigateLogin } from '../../../../utils/services/common';
import ProgressBar from "@ramonak/react-progress-bar";
import back from "../../../../assets/images/searchproperty/back_month.svg";
import fordward from "../../../../assets/images/searchproperty/ford_month.svg";
import Close from "../../../../assets/images/searchproperty/close.svg";
import enGB from 'date-fns/locale/en-GB';

const SelectSection = styled.div`
    // margin: 40px 10px 10px 10px;
    font-family: Proxima Nova;
    overflow-x:hidden
`
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "700px",
        height: "230px",
    },
};

const FinalStep = () => {
    const param = useParams()
    const today = new Date()
    const navigate = useNavigate()
    const [inputValue, setInputValue] = useState('');
    const [checkpoint, setCheckpoint] = useState(false);
    const [showorganization, setShoworganization] = useState(localStorage.getItem("showorganization") !== null ? localStorage.getItem("showorganization") : false);
    const [isinputValueEmpty, setisinputValueEmpty] = useState(false);
    // const [isDateEmpty, setIsDateEmpty] = useState(false);
    // const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [planId, setPlanId] = useState('')
    const [subscriptionSelected, setSubscriptionSelected] = useState(false);
    const [ShowOneTimelist, setShowOneTimelist] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [depositAmount, setDepositAmount] = useState("");
    const [subscriptionbutton, setSubscriptionbutton] = useState(localStorage.getItem("subscriptionbutton") !== null ? localStorage.getItem("subscriptionbutton") : "")
    const [masterSubscrption, setMasterSubscrption] = useState("")
    const [subscriptionoption, setSubscriptionoption] = useState([])
    const [subscriptionOptionDetail, setSubscriptionOptionDetail] = useState([])
    const [oneTimeSubscription, setOneTimeSubscription] = useState([])
    const [availabileData, setAvailabileData] = useState([])
    const [subscriptionDetailValue, setSubscriptionDetailValue] = useState({})
    const [listingActive, setListingActive] = useState("")
    const [totalListing, setTotalListing] = useState("")
    const [activeStep, setActiveStep] = useState(0)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const updateScreenSize = () => {
        setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener('resize', updateScreenSize);

        return () => window.removeEventListener('resize', updateScreenSize);
    }, []);

    // const customStylesDate = {
    //     content: {
    //         top: screenSize.width < 767 ? '95%' : '50%',
    //         left: '50%',
    //         right: 'auto',
    //         bottom: 'auto',
    //         marginRight: '-50%',
    //         transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-50%, -50%)',
    //         width: screenSize.width < 767 ? '101%' : screenSize.width <= 800 ? "90vw" : screenSize.width <= 1100 ? "60vw" : "40vw",
    //         height: screenSize.width < 767 ? '1000px' : screenSize.width < 800 ? "64vh" : screenSize.width < 900 ? "55vh" : screenSize.width < 950 ? "48vh" : screenSize.width <= 1100 ? "48" : screenSize.width <= 1700 ? "77vh" : "68vh",
    //         padding: '0px',
    //         borderRadius: screenSize.width < 767 ? '24px 24px 0px 0px' : '0px',
    //     },
    // };

    const customStylesDate = {
        content: {
            top: screenSize.width < 767 ? '95%' : '50%',
            left: '50%',
            right: 'auto',
            bottom: screenSize.width < 767 ? '0px' : 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)', // Same transform for all screen sizes
            width: screenSize.width < 767 ? '101%' : screenSize.width <= 800 ? "64vh" : screenSize.width <= 900 ? "55vh" : screenSize.width <= 950 ? "48vh" : screenSize.width <= 1100 ? "48vh" : screenSize.width <= 1700 ? "77vh" : "68vh",
            height: screenSize.width < 767 ? '1200px' : screenSize.width <= 800 ? '64vh' : screenSize.width === 820 ? '56vh' : screenSize.width <= 950 ? "48vh" : screenSize.width <= 1100 ? '48vh' : screenSize.width <= 1700 ? '77vh' : '75vh',
            padding: '0px',
            borderRadius: screenSize.width < 767 ? '24px 24px 0px 0px' : '8px',
        },
    };


    const handlesubscription = () => {
        setShowOneTimelist((prevValue) => !prevValue);
        if (!ShowOneTimelist) {
            getSubscriptionDetails(true)
        } else {
            getSubscriptionDetails(false)
        }
    }
    const handlesubscriptionbutton = (e) => {
        localStorage.setItem("subscriptionbutton", e.name)
        setSubscriptionbutton(e.name)
        setMasterSubscrption(e?.subscription_details?.[0]?.id)
        localStorage.setItem("masterListSubscriptionId", e?.subscription_details?.[0]?.id)
        setPlanId(e.planId)
    }
    // const handleChange = (date) => {
    //     setSelectedDate(date);
    //     localStorage.setItem("date", date.toISOString());
    //     setShowCalendar(false);
    // };
    const handleChangeDate = (date) => {
        setSelectedDate(date);
        localStorage.setItem("date", date?.toISOString());
        // setIsDateEmpty(false);
    };

    const handleInputChange = (value) => {
        setInputValue(value);
        setisinputValueEmpty(value.trim() === '');
    };

    // const handleBlur = () => {
    //     if (!selectedDate && selectedDate !== '') {
    //         setIsDateEmpty(true);
    //     } else {
    //         setIsDateEmpty(false);
    //     }
    // };
    useEffect(() => {
        if (subscriptionSelected) {
            setSubscriptionSelected(false);
        }
    }, [selectedPlan, subscriptionSelected]);

    useEffect(() => {
        console.log("Checkpoint:", checkpoint);
    }, [checkpoint]);

    const handleNegotiation = () => {
        setCheckpoint(!checkpoint);
    };

    const handleMyorgnization = () => {
        setShoworganization(!showorganization);
        localStorage.setItem("showorganization", !showorganization);
    };

    useEffect(() => {
        setSubscriptionterm()
        getSubscriptionDetails()
    }, [])
    const setSubscriptionterm = async () => {
        await getMethodWithToken(url.selectList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                response?.data.map((item) => {
                    if (item?.[1] === "subscription_term") {
                        setSubscriptionoption(item?.[0])
                    }
                    if (item?.[1] === "subscription_type") {
                        setAvailabileData(item?.[0])
                    }
                })
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const getSubscriptionDetails = async (value = false) => {
        let urls
        if (value) {
            urls = url.subscriptionList + `?is_one_time_listing=True`
        } else {
            urls = url.subscriptionList
        }
        await getMethodWithToken(urls).then((response) => {
            if (response.status === 200 || response.status === 201) {
                if (value) {
                    // let newArr = response?.data.slice(0).reverse().map((val) => {
                    //     return val;
                    // }
                    // );
                    // setOneTimeSubscription(newArr)
                    setOneTimeSubscription(response?.data)
                } else {
                    setSubscriptionOptionDetail(response?.data)
                }
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const [subscriptionType, setSubscriptionType] = useState(localStorage.getItem("subscriptionType") !== null ? localStorage.getItem("subscriptionType") : '');
    const [availabilityValue, setAvailabilityValue] = useState(localStorage.getItem("availabilityValue") !== null ? localStorage.getItem("availabilityValue") : "")
    const [availabilityValueResponse, setAvailabilityValueResponse] = useState(localStorage.getItem("availabilityValueResponse") !== null ? localStorage.getItem("availabilityValueResponse") : "")
    const [subscriptionId, setSubscriptionId] = useState(localStorage.getItem("subscriptionId") !== null ? localStorage.getItem("subscriptionId") : '');
    const [subscriptionValueId, setSubscriptionValueId] = useState("")
    const [openRentModal, setOpenRentModal] = useState(false)
    const handleDropdownChange = (event, key) => {
        setSubscriptionType(event?.target?.value);
        localStorage.setItem("subscriptionId", + key.key.substring(2));
        setSubscriptionId(+ key.key.substring(2))
    };
    const handleAvailChange = (event) => {
        setAvailabilityValue(event?.target?.value)
        localStorage.setItem("availabilityValue", event?.target?.value)
        setSubscriptionValueId("")
        setMasterSubscrption("")
    }
    useEffect(() => {
        getProfile()
    }, [])
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const getProfile = async () => {
        await getMethodWithToken(url.userProfile).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setSubscriptionDetailValue(response?.data?.subscription_detail)
                localStorage.setItem("subscriptionData", JSON.stringify(response?.data?.subscription_detail))
                if (response?.data?.subscription_detail && Object.keys(response?.data?.subscription_detail).length > 0 && Object.keys(response?.data?.subscription_detail)?.length > 0 && response?.data?.subscription_detail?.remaining_list > 0) {
                    setAvailabilityValue(response?.data?.subscription_detail?.Subscription_type?.length > 0 && getUpparCaseName(response?.data?.subscription_detail?.Subscription_type))
                    localStorage.setItem("availabilityValue", response?.data?.subscription_detail?.Subscription_type?.length > 0 && getUpparCaseName(response?.data?.subscription_detail?.Subscription_type))
                    setAvailabilityValueResponse(response?.data?.subscription_detail?.Subscription_type?.length > 0 && getUpparCaseName(response?.data?.subscription_detail?.Subscription_type))
                    localStorage.setItem("availabilityValueResponse", response?.data?.subscription_detail?.Subscription_type?.length > 0 && getUpparCaseName(response?.data?.subscription_detail?.Subscription_type))
                    setSubscriptionValueId(response?.data?.subscription_detail?.subscription_id)
                    setListingActive(response?.data?.subscription_detail?.listed_property)
                    setTotalListing(response?.data?.subscription_detail?.total_list_property)
                    localStorage.setItem("masterListSubscriptionId", response?.data?.subscription_detail?.subscription_id)
                }
            }
        })
    }

    useEffect(() => {
        getSteps()
    }, [])

    const getSteps = async () => {
        await getMethodWithToken(url.selectlist + param.id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                const allDetailStepSix = response?.data?.detail?.stepSix;
                if (Object.keys(allDetailStepSix).length > 0) {
                    setInputValue(allDetailStepSix?.price)
                    setDepositAmount(allDetailStepSix?.deposit_amount)
                    setCheckpoint(allDetailStepSix?.is_negotiable)
                    setSelectedDate(allDetailStepSix && new Date(allDetailStepSix?.propertyAvailfrom))
                }
                setActiveStep(response?.data?.detail?.stepsCompleted)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    // useEffect(() => {
    //     if (openRentModal) {
    //         document.body.style.overflowY = 'hidden';
    //     } else {
    //         document.body.style.overflowY = 'auto';
    //     }
    //     return () => {
    //         document.body.style.overflowY = 'auto';
    //     };
    // }, [openRentModal]);

    useEffect(() => {
        if (openRentModal) {
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
            }, 0);
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [openRentModal]);

    useEffect(() => {
        if (availabilityValue !== "") {
            if (availabilityValue === "Short Term") {
                setSubscriptionType(subscriptionoption?.[0]?.name);
            } else {
                setSubscriptionType(subscriptionoption?.[1]?.name);
            }
        }
    }, [availabilityValue, subscriptionoption]);

    const handleFinalStep = async () => {
        if (availabilityValue !== "") {
            if (masterSubscrption !== "" || subscriptionValueId !== "") {
                const perMonthValue = subscriptionType === 'Per Month' ? true : false;
                const perDayValue = subscriptionType === 'Per Day' ? true : false
                let body = {
                    "masterSubscrption": (Object.keys(subscriptionDetailValue).length > 0 && availabilityValue === availabilityValueResponse) ? subscriptionValueId : masterSubscrption,
                    "price": inputValue,
                    "per_month": perMonthValue,
                    "per_day": perDayValue,
                    "is_negotiable": !!checkpoint,
                    // "show_this_to_myorgnization": JSON.parse(showorganization),
                    "expected_deposit_amount": depositAmount,
                    "propertyAvailfrom": selectedDate,
                    "subscription_id": "",
                    "deposit_amount": depositAmount,
                    "available_slot": availabilityValue
                };
                const areFieldsEmpty = !selectedDate || !inputValue;

                await postMethodWithToken(url.setPrice + param.id + '/', body).then((response) => {
                    if (inputValue && !areFieldsEmpty) {
                        if (response.status === 200 || response.status === 201) {
                            navigate(`/Dashboard/FavoritePropertieList/Review/${response.data.propertyId}`);
                        }
                    }
                    else {
                        setisinputValueEmpty(!inputValue);
                        toast.error("Please Select Available Date")
                    }
                });
            } else {
                toast.error("Please Select Subscription Package")
            }
        } else {
            toast.error("Please Select Availability Type")
        }
    }

    const handleSaveExit = () => {
        // const perMonthValue = subscriptionType === 'per Month';
        // let body = {
        //     "masterSubscrption": +masterSubscrption,
        //     "price": inputValue,
        //     "per_month": perMonthValue,
        //     "per_day": !perMonthValue,
        //     "is_negotiable": checkpoint,
        //     "show_this_to_myorgnization": showorganization,
        //     "expected_deposit_amount": depositAmount,
        //     "propertyAvailfrom": selectedDate
        // };
        // const areFieldsEmpty = !selectedDate || !inputValue;
        // await postMethodWithToken(url.setPrice + param.id + '/', body).then((response) => {
        //     if (inputValue && !areFieldsEmpty) {
        //         if (response.status === 200 || response.status === 201) {
        //             navigate("/Dashboard/homePage")
        //         }
        //     }
        //     else {
        //         setisinputValueEmpty(!inputValue);
        //         toast.error("Please Select one field")
        //     }
        // });
        navigate("/Dashboard/homePage")
    }
    useEffect(() => {
        console.log(ShowOneTimelist);
    }, [ShowOneTimelist]);

    // useEffect(() => {
    //     if (openModal) {
    //         setTimeout(() => {
    //             document.body.style.overflow = 'hidden';
    //         }, 0);
    //     } else {
    //         document.body.style.overflowY = 'auto';
    //     }
    //     return () => {
    //         document.body.style.overflowY = 'auto';
    //     };
    // }, [openModal]);

    useEffect(() => {
        if (openModal) {
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
            }, 0);
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [openModal]);

    const handleOpenBack = () => {
        setOpenModal(true)
    }
    const handleCloseBack = () => {
        setOpenModal(false)
    }

    const handleAmountChange = (event) => {
        setDepositAmount(event.target.value);
    };
    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    const handleRentProperty = () => {
        setOpenRentModal(true)
    }
    const handleCloseRentClick = () => {
        setOpenRentModal(false)
    }

    const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
        const monthYear = date.toLocaleDateString(undefined, {
            month: 'long',
            year: 'numeric',
        });

        const days = ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'];

        return (
            <div className="custom-header">
                <div className='date_month'>
                    <p className='monthyear'>{monthYear}</p>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <img src={back} onClick={decreaseMonth} alt='back' style={{ cursor: "pointer" }} className='forward' />
                        <img src={fordward} onClick={increaseMonth} alt='fordward' style={{ cursor: "pointer" }} className='forward' />
                    </div>

                </div>
                <div style={{ justifyContent: 'space-evenly', display: 'flex', fontFamily: 'Proxima_nove_reg', paddingBottom: '20px' }} className='days'>
                    {days.map((day, index) => (
                        <span key={index}>{day}</span>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <>
            <SelectSection style={{ overflow: 'hidden' }} className='SelectSection'>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <FavoriteSelect activeStep={activeStep} value={5} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={7} className='col_mainContent' >
                        <div className="mainContent" style={{ overflowX: 'hidden' }}>
                            <div className='first-heading'>Final Step!</div>
                            <p className='description'>Now we just need a price, a few other details and we’re set to go.</p>
                            <div className='d-flex ps-2 ps-md-5 justify-content-md-center ps-md-4 justify-content-start'>
                                <div style={{ display: 'flex', borderBottom: '2px solid #EAEAEA', alignItems: 'baseline' }} className='inputfied'>
                                    <TextField variant="standard" value={inputValue} InputProps={{
                                        disableUnderline: true, startAdornment: (
                                            <InputAdornment position="start">
                                                <p className='dollar'>$</p>
                                            </InputAdornment>
                                        ), style: { fontSize: '32px', fontWeight: '600', color: 'black' }
                                    }} onChange={(e) => handleInputChange(e?.target?.value)} sx={{ width: '15ch' }} />
                                    <FormControl variant="standard" >
                                        <Select
                                            value={subscriptionType}
                                            onChange={handleDropdownChange}
                                            disableUnderline
                                            disabled
                                            style={{ fontSize: '20px' }}
                                            sx={{
                                                "&.Mui-disabled": {
                                                    color: 'red !important',
                                                },
                                            }}
                                        >
                                            {subscriptionoption.map((option) => (
                                                <MenuItem key={option?.id} value={option?.name} style={{ color: "red" }}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {isinputValueEmpty && (
                                <p style={{ color: 'red', marginLeft: '15px', justifyContent: 'center', display: 'flex', marginTop: '10px' }}>This field is required.</p>
                            )}


                            <div className="d-flex justify-content-md-center  ps-2 ps-md-5 justify-content-start" >
                                <div className="mt-3" style={{ fontSize: "20px", fontWeight: 500 }}>Availability</div>
                                <FormControl sx={{ minWidth: 180, height: "42px" }}>
                                    <Select
                                        value={availabilityValue}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        onChange={(e) => handleAvailChange(e)}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        sx={{
                                            boxShadow: "none",
                                            marginLeft: "10px",
                                            background: "white",
                                            ".MuiOutlinedInput-notchedOutline": {
                                                borderTop: 0,
                                                borderLeft: 0,
                                                borderRight: 0,
                                                borderRadius: 0
                                            },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                borderTop: 0,
                                                borderLeft: 0,
                                                borderRight: 0,
                                                borderRadius: 0
                                            },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                                borderTop: 0,
                                                borderLeft: 0,
                                                borderRight: 0,
                                                borderRadius: 0
                                            },
                                        }}
                                    >
                                        {availabileData && availabileData.length > 0 && availabileData.map((item, index) => {
                                            return (
                                                <MenuItem key={item?.id} value={item?.name}>{item.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="checkbox mt-4 mb-4 d-flex justify-content-md-center justify-content-start ps-2 ps-md-5 pe-4 ps-md-4 align-items-md-baseline align-items-center ">
                                <input
                                    type="checkbox"
                                    id="verifyNegotiation"
                                    checked={checkpoint}
                                    onChange={handleNegotiation}
                                    style={{ border: '2px solid black', borderRadius: '0' }}
                                />
                                <label htmlFor="verifyNegotiation" className="ps-2 lableContent text-start" style={{ color: '#505050' }}>
                                    I want to keep this price negotiable
                                </label>
                            </div>

                            <form style={{ marginTop: '30px', display: "flex", justifyContent: "center" }}>
                                <Row className='justify-content-start justify-content-md-center ps-2 ps-md-0'>
                                    {/* <Col md={1} lg={0} xl={0} xxl={0} /> */}
                                    <Col xs={10} sm={10} md={5} lg={5} xl={5} xxl={5} className='DepositAmount'>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            value={depositAmount}
                                            // placeholder={'Expected Deposit Amount'}
                                            label="Expected Deposit Amount"
                                            onChange={(e) => handleAmountChange(e)}
                                            className="DepositAmount-value"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <p style={{ marginTop: "16px", fontSize: '28px', fontWeight: '500', color: 'black' }} >$</p>
                                                    </InputAdornment>
                                                ),
                                                style: { fontSize: '20px', fontWeight: '400', color: 'black', marginTop: "10px" }
                                            }}
                                        />
                                    </Col>
                                    <Col xs={10} sm={10} md={1} lg={1} xl={1} xxl={1}></Col>
                                    <Col xs={10} sm={10} md={6} lg={6} xl={6} xxl={6} className='amount-date' >
                                        <TextField
                                            onClick={handleRentProperty}
                                            fullWidth
                                            variant="standard"
                                            value={selectedDate && selectedDate ? format(selectedDate, 'd MMM yyyy') : ''}
                                            label="Property available from"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <img src={calender} alt='calender' style={{ marginLeft: "-25px" }} />
                                                    </InputAdornment>
                                                ),
                                            }}

                                        />
                                    </Col>
                                </Row>

                                <Modal style={customStylesDate} isOpen={openRentModal} overlayClassName="myoverlay" >
                                    <div className="modal-container" >
                                        <div style={{ borderBottom: " 1px solid #EAEAEA", marginBottom: "10px" }} >
                                            <div className="modal-main-heading  calender_header" > <p style={{ fontWeight: '600', fontFamily: 'Proxima_nova_reg', marginBottom: "0px" }} className='dateRange'>Select date range</p>
                                                <img src={Close} onClick={handleCloseRentClick} style={{ cursor: 'pointer' }} alt='close' className='close' /></div>
                                            <div style={{ display: "flex" }}>
                                                <div className='inputField_calender-finial'>
                                                    <FormInput
                                                        type='text' name={'Start Date'} id={'name'}
                                                        value={selectedDate ? selectedDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }) : ''}
                                                        readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='main_calender'>
                                            <DatePicker selected={selectedDate} onChange={handleChangeDate} inline dateFormat="d MMM yyyy" dayClassName={(date) => {
                                                let classes = '';
                                                if (selectedDate) {
                                                    if (date.getTime() === new Date(selectedDate).getTime()) {
                                                        classes += 'start-date';
                                                    }
                                                }
                                                return classes;
                                            }} renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />)}
                                                locale={enGB}
                                                formatWeekDayShort={(locale, dayOfWeek) => {
                                                    const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
                                                    return days[dayOfWeek];
                                                }} minDate={today}
                                            />
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "end", padding: "20px 32px 20px 32px ", borderTop: "1px solid #EAEAEA", alignItems: "baseline" }}>
                                            <div>
                                                <button className='continue_button' onClick={handleCloseRentClick} style={{ width: '217px', background: '#0075CA' }} >Done</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </form>
                            {/* <div className="checkbox mt-4 mb-4 d-flex justify-content-md-center justify-content-start ps-2 ps-md-4 pe-4 align-items-center align-items-md-baseline " >
                                <input type="checkbox" id="verifyOrganization" checked={showorganization} onChange={handleMyorgnization} style={{ border: '2px solid black', borderRadius: '0' }} className='checkbox-final' />
                                <label htmlFor="verifyOrganization" className="ps-2 lableContent text-start" style={{ color: '#505050' }}>Show this property only to people in my organization</label>
                            </div> */}
                            {(Object.keys(subscriptionDetailValue)?.length && Object.keys(subscriptionDetailValue)?.length && subscriptionDetailValue?.remaining_list > 0) ?
                                ((availabilityValue !== availabilityValueResponse) ?
                                    <div>
                                        <p className='paypal-select mt-3'>Select a Subscription Package</p>
                                        <p style={{ textAlign: 'center', color: '#919CA5' }}>Any unused listing will be carried over to the next month</p>
                                        <div className="d-flex justify-content-center row subscription-row">
                                            {subscriptionOptionDetail && subscriptionOptionDetail.map((item, index) => {
                                                if (item?.name && getUpparCaseName(item?.name) === availabilityValue) {
                                                    return (
                                                        <>
                                                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 subscription-box-container-change" >
                                                                <div className="item-heading">{item?.name?.length > 0 && getUpparCaseName(item?.name)} <span className="item-below-description">({item?.description})</span></div>
                                                                <div className="subscribe-button" onClick={() => handlesubscriptionbutton(item)} style={{ border: masterSubscrption === item?.subscription_details?.[0]?.id ? "3px solid #0075CA" : "2px solid #505050" }}>
                                                                    <div className="amount-term">{item?.subscription_details?.[0]?.pricePerListing} <span className="listing-amount">{item?.subscription_details?.[0]?.listing_text}</span></div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                            })}

                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className="d-flex justify-content-center mt-3" style={{ fontSize: "20px", fontWeight: 500 }}>Current Plan</div>
                                        <div className="d-flex justify-content-center mt-1" style={{ fontSize: "22px", fontWeight: 500, color: "#0075CA" }}>{availabilityValue}</div>
                                        <div className="d-flex justify-content-center mt-2" >
                                            <div className="d-flex justify-content-between currentPlan" >
                                                <div style={{ fontSize: "16px", fontWeight: 600 }}>Listings</div>
                                                <div style={{ fontSize: "18px", fontWeight: 700 }}>{subscriptionDetailValue?.remaining_list}<span>/{subscriptionDetailValue?.total_list_property}</span></div>
                                            </div>
                                        </div>
                                        {console.log((listingActive / totalListing) * 100)}
                                        <div className="d-flex justify-content-center mt-2" >
                                            <ProgressBar completed={(listingActive / totalListing) * 100} bgColor="#0075CA" isLabelVisible={false} height="10px" className='progressBar' />
                                        </div>
                                    </>

                                )
                                :
                                Object.keys(subscriptionDetailValue)?.length && subscriptionDetailValue?.remaining_list <= 0 ?
                                    <div>
                                        {ShowOneTimelist === false ? <>
                                            <div>
                                                <p className='paypal-select mt-3'>Select a Subscription Package</p>
                                                <p style={{ textAlign: 'center', color: '#919CA5' }}>Any unused listing will be carried over to the next month</p>
                                                <div className="row d-flex justify-content-center subscription-row">
                                                    {subscriptionOptionDetail && subscriptionOptionDetail.map((item, index) => {
                                                        return (
                                                            <>
                                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 subscription-box-container" >
                                                                    <div className="item-heading">{item?.name?.length > 0 && getUpparCaseName(item?.name)} <span className="item-below-description">({item?.description})</span></div>
                                                                    <div className="subscribe-button" onClick={() => (availabilityValue === getUpparCaseName(item?.subscription_details?.[0]?.name) || availabilityValue === "") && handlesubscriptionbutton(item)} style={{ border: masterSubscrption === item?.subscription_details?.[0]?.id ? "3px solid #0075CA" : "2px solid #505050" }}>
                                                                        <div className="amount-term">{item?.subscription_details?.[0]?.pricePerListing} <span className="listing-amount">{item?.subscription_details?.[0]?.listing_text}</span></div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}

                                                </div>
                                            </div>
                                            <div className='footer'>
                                                <p className='or'>OR</p>
                                                <a className='footerlink' onClick={handlesubscription}>See plans for One time Listing</a>
                                            </div>
                                        </> :
                                            <>
                                                <div>
                                                    <p className='paypal-select mt-3'>Select One Time Listing Package</p>
                                                    <div className="row subscription-row">
                                                        {oneTimeSubscription && oneTimeSubscription.length > 0 && oneTimeSubscription.map((item, index) => {
                                                            return (
                                                                <div className="col-sm-12 col-md-6 subscription-box-one-time-container">
                                                                    <div className="item-heading">{item?.name} <span className="item-below-description">({item?.description})</span></div>
                                                                    <div className="subscribe-button" onClick={() => (((item?.name.includes(availabilityValue) || availabilityValue === "Mid Term") && handlesubscriptionbutton(item)))} style={{ border: masterSubscrption === item?.subscription_details?.[0]?.id ? '3px solid #0075CA' : '3px solid #505050' }}>
                                                                        <div className="amount-term">Free <span className="listing-amount">{item?.subscription_details?.[0]?.listing_text}</span></div>
                                                                        <div className="amount-term-listing"> {index === 0 ? item?.subscription_details?.[0]?.pricePerListing.slice(1) + "%" : item?.subscription_details?.[0]?.pricePerListing}<span className="listing-amount">{item?.subscription_details?.[0]?.description}</span></div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                    <p style={{ textAlign: 'center', color: '#919CA5', fontSize: '20px', paddingTop: '15px' }}>*If rental payment is processed through website, 3% to renter and 5% to lister of total price</p>
                                                </div>
                                                <div className='footer'>
                                                    <p className='or'>OR</p>
                                                    <a className='footerlink' onClick={handlesubscription}>See Subscription Packages</a>
                                                </div>
                                            </>}
                                    </div>
                                    :
                                    <div>
                                        {ShowOneTimelist === false ? <>
                                            <div>
                                                <p className='paypal-select mt-3'>Select a Subscription Package</p>
                                                <p style={{ textAlign: 'center', color: '#919CA5' }}>Any unused listing will be carried over to the next month</p>
                                                <div className="row d-flex justify-content-center subscription-row">
                                                    {subscriptionOptionDetail && subscriptionOptionDetail.map((item, index) => {
                                                        return (
                                                            <>
                                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 subscription-box-container" >
                                                                    <div className="item-heading">{item?.name?.length > 0 && getUpparCaseName(item?.name)} <span className="item-below-description">({item?.description})</span></div>
                                                                    <div className="subscribe-button" onClick={() => (availabilityValue === getUpparCaseName(item?.subscription_details?.[0]?.name) || availabilityValue === "") && handlesubscriptionbutton(item)} style={{ border: masterSubscrption === item?.subscription_details?.[0]?.id ? "3px solid #0075CA" : "3px solid #505050" }} disabled={availabilityValue === getUpparCaseName(item?.name)}>
                                                                        <div className="amount-term">{item?.subscription_details?.[0]?.pricePerListing} <span className="listing-amount">{item?.subscription_details?.[0]?.listing_text}</span></div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}

                                                </div>

                                                {/* </div> */}
                                            </div>
                                            <div className='footer'>
                                                <p className='or'>OR</p>
                                                <a className='footerlink' onClick={handlesubscription}>See plans for One time Listing</a>
                                            </div>
                                            {/* {continueClicked && !selectedPlan &&   <p style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>Please select a subscription plan.</p>} */}
                                        </> :
                                            <>
                                                <div>
                                                    <p className='paypal-select mt-3'>Select One Time Listing Package</p>
                                                    <div className="row subscription-row">
                                                        {oneTimeSubscription && oneTimeSubscription.length > 0 && oneTimeSubscription.map((item, index) => {
                                                            return (
                                                                <div className="col-sm-12 col-md-6 subscription-box-one-time-container">
                                                                    <div className="item-heading">{item?.name} <span className="item-below-description">({item?.description})</span></div>
                                                                    <div className="subscribe-button" onClick={() => (((item?.name.includes(availabilityValue) || availabilityValue === "Mid Term") && handlesubscriptionbutton(item)))} style={{ border: masterSubscrption === item?.subscription_details?.[0]?.id ? '3px solid #0075CA' : '3px solid #505050' }}>
                                                                        <div className="amount-term">Free <span className="listing-amount">{item?.subscription_details?.[0]?.listing_text}</span></div>
                                                                        <div className="amount-term-listing"> {index === 0 ? item?.subscription_details?.[0]?.pricePerListing.slice(1) + "%" : item?.subscription_details?.[0]?.pricePerListing}<span className="listing-amount">{item?.subscription_details?.[0]?.description}</span></div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                    <p style={{ textAlign: 'center', color: '#919CA5', fontSize: '20px', paddingTop: '15px' }}>*If rental payment is processed through website, 3% to renter and 5% to lister of total price</p>
                                                </div>
                                                <div className='footer'>
                                                    <p className='or'>OR</p>
                                                    <a className='footerlink' onClick={handlesubscription}>See Subscription Packages</a>
                                                </div>
                                                {/* {!selectedPlan &&  <p style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>Please select a one-time listing plan.</p>} */}
                                            </>}
                                    </div>
                            }

                        </div>
                    </Col>
                </Row>

                <div className="d-md-flex d-none  process-value justify-content-between" style={{ margin: "30px 0 20px 30px", alignItems: "center" }} >
                    <div className="backButton-price ">
                        <ContinueButton className="back-list" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={handleOpenBack} fontSize={"20px"} fontWeight={"400"} />
                    </div>
                    <div className="d-flex justify-content-end">
                        <div style={{ marginRight: "50px", display: "flex", alignItems: "center" }}>
                            <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                        </div>
                        <div className="saveButton-price">
                            <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleFinalStep()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                        </div>
                    </div>
                </div>
                <div className="d-md-none d-flex justify-content-between  m-3">
                    <div className="saveExit-price" style={{ display: "flex", alignItems: "center" }} >
                        <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={screenWidth < 767 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} />
                    </div>
                    <div className="saveButton-mobile-price">
                        <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleFinalStep()} width={screenWidth < 767 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} />
                    </div>
                </div>
            </SelectSection>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="d-flex justify-content-center">
                    <div className="back-heading">Are you sure you want to back?</div>
                </div>
                <div className="d-flex justify-content-center" >
                    <div style={{ marginTop: "10px" }}>All the changes you will made will be discarded</div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex" style={{ marginTop: "50px" }}>
                        <div className="stay-heading" onClick={handleCloseBack}>Stay on this page</div>
                        <div>
                            <ContinueButton text="Go back" color={'#fff'} onClick={() => navigate(-1)} width={"210px"} borderRadius={"4px"} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default FinalStep;
