import React from 'react'
import { useNavigate } from 'react-router-dom';
import './BackButton.scss'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import onlyLogo from '../../../assets/icons/onlyLogo.png'
const BackButton = (props) => {
    const navigate = useNavigate();
    const defaultValue = {
        color: '#fff',
        backgroundColor: 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)',
        border: 'none',
        height: '50px',
        width: '250px',
        maxWidth: '',
        text: 'Click',
        borderRadius: '',
        fontWeight: '',
        fontSize: ''
        
    }
    return (
        <>
            {/* <div className="back" onClick={() => navigate(props.send)}>
            {props.backText}
        </div>
        <div className="back smallBack justify-content-between" style={{fontSize:'30px', fontWeight:'600',lineHeight:'0.8'}} onClick={() => navigate(props.send)}>
            <div className='d-flex'>
            <ArrowBackIcon/>
            <div className="div ms-2"> {props.text}</div>
            </div>
            {window.location.pathname!=='/propertyManager' && window.location.pathname!=='/federalEmployee'?
             <img src={onlyLogo} alt="" style={{maxWidth:'30px', maxHeight:'30px'}} />:<></>}
        </div> */}
            <button className={`${props.className}`}
                style={{
                    color: props.color ? props.color : defaultValue.color,
                    background: props.backgroundColor ? props.backgroundColor : defaultValue.backgroundColor,
                    border: props.border ? props.border : defaultValue.border,
                    width: props.width ? props.width : defaultValue.width,
                    height: props.height ? props.height : defaultValue.height,
                    cursor: 'pointer',
                    maxWidth: props.maxWidth ? props.maxWidth : defaultValue.maxWidth,
                    borderRadius: props.borderRadius ? props.borderRadius : defaultValue.borderRadius,
                    fontWeight: props.fontWeight ? props.fontWeight : defaultValue.fontWeight,
                    fontSize: props.fontSize ? props.fontSize : defaultValue.fontSize
                }}
                disabled={props.disabled}
                onClick={props.onClick}
            >{props.text ? props.text : defaultValue.text}</button>
        </>
    )
}

export default BackButton
