import React, { useState } from 'react';
import Navbar from '../../../Components/commonComponents/navbar/Navbar'
import styled from 'styled-components';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Col, Row } from 'react-bootstrap';
import SearchNav from '../SearchNav/SearchNav';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import "./SearchMap.scss";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import DefaultImage from "../../../assets/images/default-image.svg"
import Bedroom from "../../../assets/images/favoriteProperty/bedroom.svg"
import Bathroom from "../../../assets/images/favoriteProperty/bathroom.svg"
import { useLocation, useNavigate } from 'react-router-dom';
import url from "../../../utils/services/urls.json";
import { getMethodWithToken } from '../../../utils/services/apis';
import { navigateLogin } from '../../../utils/services/common';
import ShowList from "../../../assets/images/searchproperty/show-list.svg"

const SearchSelect = styled.div`
padding:15px 15px 0 15px;
background:#F2F2F7;
// height:100vh;
font-family: 'Proxima Nova';
`

const CustomImage = styled.img`
background:url('${props => props.image}');
width: 224px;
height:120px;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;
// padding-top:10px;
padding: 10px 0px 10px 10px;

 @media (max-width:575px) {
   padding: 10px 0px 4px 4px;
   width: 110px;
  }
`
const center = {
    lat: -3.745,
    lng: -38.523
};

const SearchMap = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [activeIndex, setActiveIndex] = useState(0);
    const [propertytypedata, setPropertytypedata] = useState('');
    const [map, setMap] = React.useState(null)
    const [indexBoolen, setIndexBoolen] = useState(false)
    const [hoveredLocation, setHoveredLocation] = useState([]);
    const [hoverIndex, setHoverIndex] = useState("")
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0
    })
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const containerStyle = {
        width: '100%',
        height: screenSize.width <= 360 ? "89vh" : screenSize.width <= 375 ? "88vh" : screenSize.width <= 390 ? "91vh" : screenSize.width <= 575 ? "88vh" : screenSize.width <= 800 ? '78vh' : screenSize.width <= 900 ? '78vh' : screenSize.width <= 950 ? '87vh' : screenSize.width <= 1100 ? "86vh" : "82vh",
        // marginBottom: "50px"
    }

    const propertyArray = location?.state?.propertyArray && JSON.parse(location?.state?.propertyArray)
    const featureArray = location?.state?.featureArray && JSON.parse(location?.state?.featureArray)

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBBNW2PnUIVek7ezyuNFTh1xn6BAj90x7s"
    })

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        // setMap(map)
        // map.fitBounds(bounds);
        map.setZoom(1); // Zoom level 1 to show the whole world
        map.setCenter({ lat: 22.9643599, lng: -100.7502949 });
        map.setOptions({
            zoomControl: false,
            streetViewControl: false,
        });

        // map.setOptions({
        //     zoomControl: false,
        //     streetViewControl: false,
        // });

        setMap(map);
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const handleChildData = (dataFromChild) => {
        setPropertytypedata(dataFromChild);
    };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: (activeIndex === featureArray?.length - 2 || activeIndex === propertyArray?.length - 2) ? 1 : 1.2,
        slidesToScroll: 1,
        beforeChange: (current) => setActiveIndex(current),
        afterChange: (current) => setActiveIndex(current),
    };


    const handleViewDtail = (propertyId) => {
        if (featureArray) {
            navigate(`/Dashboard/featureList/${propertyId}/feature_deatil`)
        } else {
            navigate(`/Dashboard/SearchList/SearchDetail/${propertyId}`)
        }
    }
    const [contactModal, setContactModal] = useState(false)
    const handleGetFilter = (val) => {
        setContactModal(val)
    }

    const handleMapViewDetail = (valueId) => {
        if (location?.state?.featureDetail) {
            navigate(`/Dashboard/featureList/${valueId}/feature_deatil`)
        } else {
            navigate(`/Dashboard/SearchList/SearchDetail/${valueId}`)
        }
    }

    return (
        <div>
            <div className='navbar-searchHome'>
                <Navbar getFilterModal={(val) => handleGetFilter(val)} />
            </div>
            <SearchSelect style={{ paddingBottom: "0px" }}>
                <SearchNav onData={handleChildData} contactModal={contactModal} modalValue={contactModal} />
            </SearchSelect>
            <Row style={{ background: '#f3f2f2', margin: "0", padding: "0" }}>
                <Col xs={12} sm={12} md={12} lg={12} className="map-medium-section">
                    {isLoaded &&
                        <div className="map-container-meduim">
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={1}
                                onLoad={onLoad}
                                onUnmount={onUnmount}>
                                {
                                    featureArray ? featureArray?.length > 0 && featureArray.map((item, ind) => {
                                        if (item?.location?.latitude && item?.location?.longitude) {
                                            return (
                                                <Marker position={{ lat: item?.location?.latitude, lng: item?.location?.longitude }} icon={{
                                                    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                                <rect 
                                                                    width="38" 
                                                                    height="38" 
                                                                    fill="white" 
                                                                    stroke="#0075CA" 
                                                                    stroke-width="2" 
                                                                    rx="50" 
                                                                    ry="50" 
                                                                    x="1" 
                                                                    y="1"
                                                                />
                                                                <text 
                                                                    x="20" 
                                                                    y="25" 
                                                                    font-size="16" 
                                                                    fill="#0075CA" 
                                                                    text-anchor="middle"
                                                                >${ind + 1}</text>
                                                            </svg>
                                                        `)}`,
                                                    scaledSize: new window.google.maps.Size(40, 40)
                                                }}
                                                    onMouseOver={() => { setHoveredLocation({ lat: item?.location?.latitude, lng: item?.location?.longitude }); setHoverIndex(ind) }}
                                                    onMouseOut={() => setHoveredLocation([])}
                                                    onClick={() => handleMapViewDetail(item?.propertyMaster_id)}
                                                />
                                            )
                                        }
                                    })
                                        :
                                        propertyArray && propertyArray.length > 0 && propertyArray.map((item, index) => {
                                            if (item?.latitude && item?.longitude) {
                                                return (
                                                    <Marker position={{ lat: item?.latitude, lng: item?.longitude }} icon={{
                                                        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                                <rect 
                                                                    width="38" 
                                                                    height="38" 
                                                                    fill="white" 
                                                                    stroke="#0075CA" 
                                                                    stroke-width="2" 
                                                                    rx="50" 
                                                                    ry="50" 
                                                                    x="1" 
                                                                    y="1"
                                                                />
                                                                <text 
                                                                    x="20" 
                                                                    y="25" 
                                                                    font-size="16" 
                                                                    fill="#0075CA" 
                                                                    text-anchor="middle"
                                                                >${index + 1}</text>
                                                            </svg>
                                                        `)}`,
                                                        scaledSize: new window.google.maps.Size(40, 40)
                                                    }} 
                                                    onMouseOver={() => { setHoveredLocation({ lat: item?.location?.latitude, lng: item?.location?.longitude }); setHoverIndex(index) }}
                                                    onMouseOut={() => setHoveredLocation([])}
                                                    onClick={() => handleMapViewDetail(item?.propertyMasterId)}
                                                    />
                                                )
                                            }
                                        })
                                }

                            </GoogleMap>
                        </div>
                    }
                </Col>
                <div className="d-flex d-xl-none justify-content-center">
                    <div className="show-list" onClick={() => navigate(-1)}>
                        <div>Show list</div>
                        <img src={ShowList} alt="" style={{ marginLeft: "12px" }} />
                    </div>
                </div>
                <div className="search-list">
                    <Slider {...settings}>
                        {propertyArray ? propertyArray.length > 0 && propertyArray.map((data, index) => {
                            return (
                                <div className="feature-card" onClick={() => handleViewDtail(data?.propertyMasterId)}>
                                    <div style={{ borderLeft: activeIndex === index ? "8px solid #0075CA" : "8px solid #ffffff" }}>
                                        <CustomImage src={data?.propertyImages?.[0]?.file_url} />
                                    </div>
                                    <div className="feature-card-detail">
                                        <div className="rent-price">${data?.price}</div>
                                        <div className="d-flex" style={{ paddingTop: "8px" }}>
                                            <div className="d-flex">
                                                <img src={Bedroom} alt="" style={{ width: "24px", height: "24px" }} />
                                                <div className="category-heading">{data?.bedroomCount}</div>
                                                <div><hr style={{ border: "1px solid red", height: "11px", marginTop: "8px", marginLeft: "10px" }} /></div>
                                            </div>
                                            <div className="d-flex">
                                                <img src={Bathroom} alt="" style={{ width: "24px", height: "24px", marginLeft: "10px" }} />
                                                <div className="category-heading">{data?.propertyType === "Room" ? data?.room_sharing : data?.bathroomCount}</div>
                                                <div><hr className="d-none d-sm-flex" style={{ border: "1px solid red", height: "11px", marginTop: "8px", marginLeft: "10px" }} /></div>
                                            </div>
                                            <div className="d-none d-sm-flex category-heading" style={{ marginLeft: "10px" }}>{data?.furnishType}</div>
                                        </div>
                                        <div className="location-name">{data?.locality}</div>
                                    </div>
                                </div>
                            )
                        })
                            :
                            featureArray && featureArray?.length > 0 && featureArray.map((data, index) => {
                                return (
                                    <div className="feature-card" onClick={() => handleViewDtail(data?.propertyMaster_id)} >
                                        <div style={{ borderLeft: activeIndex === index ? "8px solid #0075CA" : "8px solid #ffffff" }}>
                                            <CustomImage src={data?.property_images?.[0]?.media?.file} />
                                        </div>
                                        <div className="feature-card-detail">
                                            <div className="rent-price">${data?.subscription_details?.price}</div>
                                            <div className="d-flex" style={{ paddingTop: "8px" }}>
                                                <div className="d-flex">
                                                    <img src={Bedroom} alt="" style={{ width: "24px", height: "24px" }} />
                                                    <div className="category-heading">{data?.balconyCount}</div>
                                                    <div><hr style={{ border: "1px solid red", height: "11px", marginTop: "8px", marginLeft: "10px" }} /></div>
                                                </div>
                                                <div className="d-flex">
                                                    <img src={Bathroom} alt="" style={{ width: "24px", height: "24px", marginLeft: "10px" }} />
                                                    <div className="category-heading">{data?.propertyType?.name === "Room" ? data?.room_sharing?.name : data?.bathroomCount}</div>
                                                    <div><hr className="d-none d-sm-flex" style={{ border: "1px solid red", height: "11px", marginTop: "8px", marginLeft: "10px" }} /></div>
                                                </div>
                                                <div className="d-none d-sm-flex category-heading" style={{ marginLeft: "10px" }}>{data?.furnishType?.name}</div>
                                            </div>
                                            <div className="location-name">{data?.location?.locality}</div>
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </Slider>
                </div>

            </Row>

        </div>
    )
}

export default SearchMap
