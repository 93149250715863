import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import FavoriteSelect from '../FavoriteSelect/FavoriteSelect';
import "./FavoriteReview.scss"
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FlagIcon from "../../../../assets/images/Flag.png"
import Bedroom from "../../../../assets/images/favoriteProperty/bedroom.svg"
import Bathroom from "../../../../assets/images/favoriteProperty/bathroom.svg"
import Clock from "../../../../assets/images/favoriteProperty/clock.svg"
import ContinueButton from '../../../../Components/Buttons/ContinueButton/ContinueButton';
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import FormControl from '@mui/material/FormControl';
import { InputLabel, MenuItem, Select } from '@mui/material'
import Modal from "react-modal"
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import url from "../../../../utils/services/urls.json"
import { navigateLogin } from '../../../../utils/services/common';
import DefaultImage from "../../../../assets/images/default-image.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import prevArrowImage from '../../../../assets/images/searchproperty/backwardarrow.svg';
import nextArrowImage from '../../../../assets/images/searchproperty/forwardarrow.svg';
import Cross from '../../../../assets/images/favoriteProperty/cross.svg';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "700px",
        height: "230px",
    },
};

const CustomImage = styled.img`
background:url('${props => props?.image}');
height: 182px;
width:100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;
cursor: pointer;

@media (min-width:915px) and (max-width: 1199px) {
     height: 162px;
   }

   @media (min-width: 820px) and (max-width: 915px) {
     height: 162px;
   }
      
 @media (min-width:575px) and  (max-width:768px) {
   height: 142px;
  }

   @media (min-width:431px) and  (max-width:575px) {
   height: 320px;
  }

   @media  (min-width:400px) and  (max-width:431px) {
   height: 220px;
  }

   @media (max-width:400px) {
   height: 190px;
  }
    
`
const ContainerSection = styled.div`
font-family: Proxima Nova;
 @media only screen and (max-width: 767px) {
   padding:10px;
   overflow-x: hidden;
 }
`
const CustomImageModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;

  
  img {
    max-width: 100%;     
    max-height: 100%;    
    object-fit: contain;  
  }
`;

const FavoriteReview = () => {
    const navigate = useNavigate()
    const moment = require('moment-timezone');
    const params = useParams()
    const theme = useTheme();
    const [activeStepValue, setActiveStepValue] = useState(0);
    const [checked, setChecked] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [reviewDetail, setReviewDetail] = useState([])
    const [collectionPropertyId, setCollectionPropertyId] = useState("")
    const [carouselImages, setCarouselImages] = useState([])
    const [featurePropertyArray, setFeaturePropertyArray] = useState([])
    const [subscriptionValue, setSubscriptionValue] = useState(localStorage.getItem("subscriptionData") !== "undefined" ? JSON.parse(localStorage.getItem("subscriptionData")) : "")
    const [arrayPropertyId, setArrayPropertyId] = useState(true)
    const [reviewDetailList, setReviewDetailList] = useState([])
    const [activeStep, setActiveStep] = useState(0)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStylesWithImage = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: screenSize.width <= 800 ? "100vw" : "100vw",
            height: screenSize.height <= 800 ? "100vh" : "100vh",
            maxHeight: "100vh",
            padding: '0px',
            overflow: 'hidden',
            position: "relative",
            backgroundColor: screenSize.width < 1030 ? "#000000" : "transparent",
            border: "none"
        },
    };

    useEffect(() => {
        getSteps()
    }, [])

    useEffect(() => {
        if (checked) {
            getSteps()
        }

    }, [checked])


    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // const getPropertyDetails = async () => {
    //     await getMethodWithToken(url.selectlist + params.id + '/').then((response) => {
    //         if (response.status === 200 || response.status === 201) {
    //             getReviewDetails(response?.data?.detail?.Property_owner?.[0]?.id)
    //         }
    //     })
    // }
    const getSteps = async () => {
        await getMethodWithToken(url.selectlist + params.id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                let Detail = response?.data?.detail
                setReviewDetailList(Detail)
                getReviewDetails(response?.data?.detail?.Property_owner?.[0]?.id)
                setCarouselImages(Detail?.stepFive)
                setActiveStep(response?.data?.detail?.stepsCompleted)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const getReviewDetails = async (userId) => {
        let apiUrl;
        if (checked) {
            apiUrl = url.getReviewDetail + params.id + "/?" + "userId=" + userId + "&is_featured=True"
        } else {
            apiUrl = url.getReviewDetail + params.id + "/?" + "userId=" + userId
        }
        await getMethodWithToken(apiUrl).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setReviewDetail(response?.data?.[0])
                if (checked) {
                    if (localStorage.getItem("availabilityValue") !== localStorage.getItem("availabilityValueResponse")) {
                        localStorage.setItem("subscriptionPriceValue", (+(response?.data?.[0]?.subscription_price) + +(response?.data?.[1]?.feature_list_price)))
                    }
                    else if (Object.keys(subscriptionValue).length > 0) {
                        localStorage.setItem("subscriptionPriceValue", (response?.data?.[1]?.feature_list_price))
                    } else {
                        localStorage.setItem("subscriptionPriceValue", (+(response?.data?.[0]?.subscription_price) + +(response?.data?.[1]?.feature_list_price)))
                    }
                } else if (Object.keys(subscriptionValue).length === 0 || localStorage.getItem("availabilityValue") !== localStorage.getItem("availabilityValueResponse")) {
                    localStorage.setItem("subscriptionPriceValue", (+(response?.data?.[0]?.subscription_price)))
                }

            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    // const getSteps = async () => {
    //     await getMethodWithToken(url.selectlist + params.id + "/").then((response) => {
    //         if (response.status === 200 || response.status === 201) {
    //             let Detail = response?.data?.detail
    //             setReviewDetailList(Detail)
    //             setCarouselImages(Detail?.stepFive)
    //             setActiveStep(response?.data?.detail?.stepsCompleted)
    //         } else if (response.status === 401) {
    //             navigateLogin()
    //             navigate("/federalEmployee/Login")
    //         }
    //     })
    // }
    const handleNext = (data) => {
        setActiveStepValue((prevActiveStep) =>
            prevActiveStep === data?.length - 1 ? 0 : prevActiveStep + 1
        );
    };
    const handleBack = (data) => {
        setActiveStepValue((prevActiveStep) =>
            prevActiveStep === 0 ? data?.length - 1 : prevActiveStep - 1
        );
    };

    const handleStepChange = (step) => {
        setActiveStepValue(step);
    };
    const handleCheck = (event) => {
        setChecked(event.target.checked)
        handleFeaturePropertyDropdown()
    }
    const handleFeaturePropertyDropdown = async () => {
        await getMethodWithToken(url.featurePropertyDropdown).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setFeaturePropertyArray(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }

        })
    }
    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    useEffect(() => {
        if (openModal) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, [openModal]);

    const handleOpenBack = () => {
        setOpenModal(true)
    }
    const handleCloseBack = () => {
        setOpenModal(false)
    }
    const handleCollectionChange = (event) => {
        setCollectionPropertyId(event.target.value)
        localStorage.setItem("collectionPropertyId", event.target.value)
    }
    const handleReview = async () => {
        if (collectionPropertyId) {
            navigate(`/Dashboard/FavoritePropertieList/PaymentDetail/${params.id}`, {
                state: {
                    featureCheck: checked,
                }
            })

        }
        else {
            navigate(`/Dashboard/FavoritePropertieList/PaymentDetail/${params.id}`)
        }
    }
    const handleAddFavouriteProperty = async () => {
        setArrayPropertyId(!arrayPropertyId)
        let body = {
            "property_id": params.id,
            "favourite": arrayPropertyId
        }
        await postMethodWithToken(url.addFavoriteProperty, body).then((response) => {
            if (response.status === 200 || response.status === 201) {

            }
        })
    }

    const handleEditProperty = () => {
        navigate(`/Dashboard/FavoritePropertieList/SelectBasic/${params.id}/${reviewDetailList?.stepOne?.propertyType?.id}`)
    }
    const handleSaveExit = () => {
        navigate(`/Dashboard/homePage`)
    }
    const handleReviewNavigate = () => {
        navigate(`/Dashboard/MyListings`)
        localStorage.removeItem("PropertyType")
        localStorage.removeItem("name")
        localStorage.removeItem("bedroom");
        localStorage.removeItem("bathroom")
        localStorage.removeItem("balcony")
        localStorage.removeItem("propertysize")
        localStorage.removeItem("furnishingid");
        localStorage.removeItem("furnishing");
        localStorage.removeItem("parkingname")
        localStorage.removeItem("parkingid")
        localStorage.removeItem("floorno")
        localStorage.removeItem("floorid")
        localStorage.removeItem("totalfloor")
        localStorage.removeItem("totalfloorid")
        localStorage.removeItem("storiesName")
        localStorage.removeItem("storiesId")
        localStorage.removeItem("petid")
        localStorage.removeItem("otherpet")
        localStorage.removeItem("locality")
        localStorage.removeItem("city")
        localStorage.removeItem("state")
        localStorage.removeItem("zipcode")
        localStorage.removeItem("latitude")
        localStorage.removeItem("latitude")
        localStorage.removeItem("direction")
        localStorage.removeItem("amenities");
        localStorage.removeItem("imageID")
        localStorage.removeItem("images")
        localStorage.removeItem("subscriptionbutton")
        localStorage.removeItem("masterSubscrption")
        localStorage.removeItem("date");
        localStorage.removeItem("inputvalue")
        localStorage.removeItem("checkpoint");
        localStorage.removeItem("showorganization");
        localStorage.removeItem("subscriptionType");
        localStorage.removeItem("subscriptionId");
        localStorage.removeItem("depositAmount");
        localStorage.removeItem("subscriptionPriceValue");
        localStorage.removeItem("availabilityValue");
        localStorage.removeItem("availabilityValueResponse");
        localStorage.removeItem("addComment")
    }

    const getLastWordWithCapital = (sentence) => {
        const words = sentence.trim().split(" ");
        if (words?.length > 1) {
            const lastWord = words[words.length - 1];
            const capitalizedLastWord = lastWord.charAt(0).toUpperCase();
            words[words.length - 1] = capitalizedLastWord + ".";
        }
        return words.join(" ");
    }

    const capitalizeEachWord = (sentence) => {
        const words = sentence.trim().split(" ");
        const capitalizedWords = words.map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
        );
        return capitalizedWords.join(" ");
    }
    const [detailImage, setDetailImage] = useState("")
    const [imageModal, setImageModal] = useState(false)
    const [indexModal, setIndexModal] = useState("")

    const handleImageModal = (id) => {
        setDetailImage(id)
        setImageModal(true)
    }
    const handleCloseImageModal = () => {
        setImageModal(false)
    }

    const CustomPrevArrow = (props) => {
        const { disabled, onClick } = props;
        return (
            <div
                className="custom-image-upload prev-arrows-image"
                onClick={(e) => {
                    e.stopPropagation(); // Stop event propagation
                    if (!disabled && onClick) onClick();
                }}
            >
                <img src={prevArrowImage} alt="Previous" />
            </div>
        );
    };

    const CustomNextArrow = (props) => {
        const { disabled, onClick } = props;
        return (
            <div
                className="custom-image-upload next-arrows-image "
                onClick={(e) => {
                    e.stopPropagation(); // Stop event propagation
                    if (!disabled && onClick) onClick();
                }}
            >
                <img src={nextArrowImage} alt="Next" />
            </div>
        );
    }

    const [currentSlide, setCurrentSlide] = useState(0);
    var settings = {
        infinite: true,
        dots: true,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        autoplaySpeed: 2000,
        beforeChange: (current, next) => setCurrentSlide(next),
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        initialSlide: +detailImage,
        appendDots: (dots) => (
            <div onClick={(e) => e.stopPropagation()}>{dots}</div>
        )
    };

    useEffect(() => {
        if (imageModal) {
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
            }, 0);
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [imageModal]);

    return (
        <>
            <ContainerSection>
                <Row className='m-md-0'>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <FavoriteSelect activeStep={activeStep} value={6} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} className='mobile-box'>
                        <div className="review-container" >
                            <div className="review-heading" >Review your property listing</div>
                            <div className="review-des" >
                                <div className="review-des-value" >See if you want to change something</div>
                            </div>
                        </div>
                        <div className="review-value-box">
                            <div className="revire-value-item">
                                <Row >
                                    <Col xs={12} sm={5} md={5} lg={4} xl={4} xxl={4}>
                                        <div>
                                            <SwipeableViews
                                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                index={activeStepValue}
                                                onChangeIndex={handleStepChange}
                                                enableMouseEvents
                                            >
                                                {reviewDetailList?.stepFive?.length > 0 && reviewDetailList?.stepFive.map((step, ind) => {
                                                    return (
                                                        <>
                                                            <div key={ind}>
                                                                <CustomImage image={step?.file} alt={step?.label} onClick={() => handleImageModal(ind)} />
                                                            </div>

                                                        </>
                                                    )
                                                })}
                                            </SwipeableViews>
                                            <MobileStepper
                                                position="static"
                                                variant="text"
                                                nextButton={
                                                    <Button size="small" style={{ color: "white" }} onClick={() => handleNext(reviewDetailList?.stepFive)}>
                                                        {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                    </Button>
                                                }
                                                backButton={
                                                    <Button size="small" style={{ color: "white" }} onClick={() => handleBack(reviewDetailList?.stepFive)}>
                                                        {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                    </Button>
                                                }
                                            />
                                        </div>


                                        <div class="d-flex justify-content-center rent-box">
                                            <div>
                                                <div className="rental-price">${reviewDetailList?.stepSix?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{reviewDetailList?.stepSix?.per_month === true ? "/month" : "/day"}</span></div>
                                                <div className="rent-heading" >Rent</div>
                                            </div>
                                            <div><hr style={{ border: "1px solid black", height: "40px", marginLeft: "10px", marginTop: "20px" }} /></div>
                                            <div>
                                                <div className="deposit-price" style={{ marginLeft: "10px" }}>$<span>{reviewDetailList?.stepSix?.deposit_amount}</span></div>
                                                <div className="rent-heading" style={{ marginLeft: "25px" }}>Deposit</div>
                                            </div>
                                        </div>
                                        <div className="deposit-type pt-3">
                                            <div className="d-flex">
                                                <div className="rent-heading" >Rent : </div>
                                                <div className="rental-price">$ {reviewDetailList?.stepSix?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{reviewDetailList?.stepSix?.per_month === true ? "/month" : "/day"}</span></div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="rent-heading">Deposit : </div>
                                                <div className="deposit-price">$ <span>{reviewDetailList?.stepSix?.deposit_amount}</span></div>

                                            </div>
                                        </div>

                                    </Col>
                                    <Col xs={12} sm={7} md={7} lg={8} xl={8} xxl={8}>
                                        <div className="detail-seaction" >
                                            <div className="d-flex justify-content-between section-profile">
                                                <div className="d-flex align-items-md-baseline align-items-center" >
                                                    <div>
                                                        <img src={reviewDetailList?.Property_owner?.[0]?.profilePic ? reviewDetailList?.Property_owner?.[0]?.profilePic : DefaultImage} alt="" style={{ borderRadius: "50%" }} className='userImage' />
                                                    </div>
                                                    <div >
                                                        <div className="d-flex" style={{ paddingLeft: "7px" }}>
                                                            <div className="user-heading">
                                                                {screenWidth <= 575 ? localStorage.getItem("userName")?.length > 0 && capitalizeEachWord(getLastWordWithCapital(localStorage.getItem("userName"))) : localStorage.getItem("userName")?.length > 0 && getUpparCaseName(localStorage.getItem("userName"))}
                                                                <p className='property-owner  d-block d-md-none'>Property Owner</p>
                                                            </div>
                                                            <div className="user-dot d-none d-md-block">.</div>
                                                            <div className="property-owner  d-none d-md-block">Property Owner</div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div>
                                                    <div className="d-md-flex d-none">

                                                        <div><img src={FlagIcon} alt="" style={{ width: "20px", height: "20px", marginLeft: "10px" }} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mobile-type">
                                                <div className="d-flex">
                                                    <div className="rent-heading" >Rent : </div>
                                                    <div className="rental-price">$ {reviewDetailList?.stepSix?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{reviewDetailList?.stepSix?.per_month === true ? "/month" : "/day"}</span></div>
                                                </div>
                                                <div className="d-flex" style={{ paddingLeft: "10px" }}>
                                                    <div className="rent-heading">Deposit : </div>
                                                    <div className="deposit-price">$ <span>{reviewDetailList?.stepSix?.deposit_amount}</span></div>

                                                </div>
                                            </div>
                                            <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }} className='review-address'>{reviewDetailList?.stepThree?.locality}</div>
                                            <Row style={{ borderBottom: "1px solid #B7B7B7", paddingBottom: "5px", paddingTop: "8px" }} >
                                                <Col xs={5} sm={5} md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                    <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                    <div className="categroy-heading-type">Bedroom</div>
                                                    <div className="category-value">{reviewDetailList?.stepTwo?.bedroomCount}</div>
                                                </Col>
                                                <Col xs={7} sm={7} md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                    <div><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                    <div className="categroy-heading-type">Bathroom</div>
                                                    <div className="category-value">{reviewDetailList?.stepOne?.propertyType?.name === "Room" ? reviewDetailList?.stepTwo?.room_sharing?.name : reviewDetailList?.stepTwo?.bathroomCount}</div>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col md={12} lg={12} xl={12} xxl={12} >
                                                    <div className="d-none d-sm-inline">
                                                        <div className="d-flex" style={{ marginTop: "10px" }} >
                                                            <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                            <div className="categroy-heading">Available from</div>
                                                        </div>
                                                        <div className="select-value-heading">{reviewDetailList?.stepSix?.propertyAvailfrom && moment.tz(reviewDetailList?.stepSix?.propertyAvailfrom, "UTC").tz("Asia/Kolkata").format('YYYY-MM-DD')}</div>

                                                    </div>

                                                </Col>
                                                <Col xs={5} sm={5} md={6} className="d-flex d-sm-none">
                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                        <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Available from</div>
                                                    </div>
                                                </Col>
                                                <Col xs={7} sm={7} md={6} className="d-flex d-sm-none">
                                                    <div className="select-value-heading">{reviewDetailList?.stepSix?.propertyAvailfrom && moment.tz(reviewDetailList?.stepSix?.propertyAvailfrom, "UTC").tz("Asia/Kolkata").format('YYYY-MM-DD')}</div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="edit-property" onClick={() => handleEditProperty()}>Edit Property Details</div>
                        </div>
                        <div className='mobile-line'>
                            <hr />
                        </div>
                        <div className="update-container" >
                            <div className="update-heading-review" >Upgrade your property to featured listing</div>
                            <div className="update-des">
                                <div className="update-des-value" >Allow your property to be featured on the front page,advertisement and stand out on the map</div>
                            </div>
                            <div className="update-check">
                                <FormCheckInput type="checkbox" name="verifyFed" id="verifyFed" onChange={handleCheck} className='update-checkbox' />
                                <label className="ps-2 check-list text-start" htmlFor="verifyFed">I want to add my property to the featured list</label>
                            </div>
                        </div>
                        {checked &&
                            <div className="select-choose">
                                <FormControl variant="standard" sx={{ m: 1, width: "350px" }} >
                                    <InputLabel id="demo-simple-select-label" >Choose Collection for your property</InputLabel>
                                    <Select
                                        required={true}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={collectionPropertyId}
                                        label="Age"
                                        onChange={(e) => handleCollectionChange(e)}
                                    >
                                        {featurePropertyArray.map((data) => {
                                            return (
                                                <MenuItem className='filter_items' value={data?.id}>{data?.name}</MenuItem>
                                            )

                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        }
                    </Col>
                    <Col>
                        <div className="d-md-flex d-none buttons" style={{ margin: "20px 0 20px 30px", alignItems: "center" }} >
                            <div className="backButton-review">
                                <ContinueButton className="back-list" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={handleOpenBack} fontSize={"20px"} fontWeight={"400"} />
                            </div>
                            <div className="d-flex justify-content-end">
                                <div style={{ paddingRight: "50px", display: "flex", alignItems: "center" }}>
                                    <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                                <div className="saveButton-review ">
                                    <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => { Object.keys(subscriptionValue)?.length > 0 ? !checked ? localStorage.getItem("availabilityValue") === localStorage.getItem("availabilityValueResponse") ? subscriptionValue?.remaining_list !== 0 && handleReviewNavigate() : handleReview() : handleReview() : handleReview() }} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                            </div>
                        </div>
                        <div className="d-md-none d-flex justify-content-between mt-3 mb-3">
                            <div className="saveExit-review" style={{ display: "flex", alignItems: "center" }} >
                                <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={screenWidth < 768 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} className="saveExitButton" />
                            </div>
                            <div className="saveButton-mobile-review">
                                <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => { Object.keys(subscriptionValue)?.length > 0 ? !checked ? localStorage.getItem("availabilityValue") === localStorage.getItem("availabilityValueResponse") ? subscriptionValue?.remaining_list !== 0 && handleReviewNavigate() : handleReview() : handleReview() : handleReview() }} width={screenWidth < 768 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} className="saveExitButton" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </ContainerSection>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="d-flex justify-content-center">
                    <div className="back-heading">Are you sure you want to back?</div>
                </div>
                <div className="d-flex justify-content-center" >
                    <div style={{ marginTop: "10px" }}>All the changes you will made will be discarded</div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex" style={{ marginTop: "50px" }}>
                        <div className="stay-heading" onClick={handleCloseBack}>Stay on this page</div>
                        <div>
                            <ContinueButton text="Go back" color={'#fff'} onClick={() => navigate(-1)} width={"210px"} borderRadius={"4px"} />
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={imageModal} style={customStylesWithImage} overlayClassName="myoverlay">
                <Row>
                    <Col>
                        <div className="modal-upload-image-section" onClick={(e) => {
                            if (screenSize.width > 1030 && !e.target.closest('.slick-dots')) {
                                handleCloseImageModal();
                            }
                        }}>
                            <div style={{ position: "absolute", cursor: 'pointer', top: screenSize.width < 575 ? "50px" : "10px", right: "10px", zIndex: "999" }}>
                                <img
                                    src={Cross}
                                    alt="Remove"
                                    onClick={handleCloseImageModal}
                                />
                            </div>
                            <Slider {...settings} style={{ width: "100%" }}>
                                {reviewDetailList?.stepFive?.length > 0 && reviewDetailList?.stepFive.map((step, ind) => {
                                    return (
                                        <>
                                            <div key={ind} style={{ position: 'relative' }}>
                                                <CustomImageModal>
                                                    <img src={step?.file} alt={step?.label} />
                                                </CustomImageModal>

                                            </div>

                                        </>
                                    )
                                })}
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default FavoriteReview
