import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Col, Row } from 'react-bootstrap';
import FavoriteSelect from '../FavoriteSelect/FavoriteSelect';
import './FavoriteUploadIamge.scss'
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton"
import { useDropzone } from "react-dropzone"
import uploadIamgeIcon from "../../../../assets/images/uploadImage.png"
import url from "../../../../utils/services/urls.json"
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import Add from '../../../../assets/images/add.svg';
import Cross from '../../../../assets/images/favoriteProperty/cross.svg';
import { toast } from 'react-toastify';
import Modal from "react-modal"
import 'react-image-crop/dist/ReactCrop.css';
import { navigateLogin } from '../../../../utils/services/common';
import EXIF from 'exif-js';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import prevArrowImage from '../../../../assets/images/searchproperty/backwardarrow.svg';
import nextArrowImage from '../../../../assets/images/searchproperty/forwardarrow.svg';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ContainerSection = styled.div`
font-family: Proxima Nova;
`

const CustomImage = styled.img`
background:url('${props => props?.image}');
width: 280px;
height: 148px;
border-radius: 4px;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;
cursor: pointer;

@media (min-width:992px) and (max-width:1024px) {
        width: 275px !important;
}

@media (min-width:760px) and (max-width:800px) {
           width: 240px;
 }

@media (min-width:800px) and (max-width:900px) {
            width: 258px;
}
 @media (min-width:530px) and (max-width:575px) {
        width: 252px !important;
         height: 130px !important;
}

@media (min-width:420px) and (max-width: 440px) {
         width: 197px !important;
         height: 133px !important;
}

@media (min-width:400px) and (max-width: 420px) {
          width: 188px !important;
          height: 120px !important;
}

@media (min-width:390px) and (max-width: 400px) {
         width: 177px !important;
        height: 88px !important;
}

@media (min-width:370px) and (max-width:389px) {
        width: 168px !important;
        height: 88px !important;
}

@media (max-width:360px) {
         width: 160px !important;
         height: 80px !important;
}
`

// const CustomImageModal = styled.div`
// // background:url('${props => props?.image}');
// // width: 100%;
// // height: 76.8vh;
// // background-size: contain;
// // background-repeat: no-repeat;
// // background-position: center center;

//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;

//   img {
//     max-width: 100%;     
//     max-height: 100%;    
//     object-fit: contain;  
//   }
// `

const CustomImageModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  
  img {
    max-width: 100%;     
    max-height: 100%;    
    object-fit: contain;  
  }
`;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "700px",
        height: "230px",
    },
};

const FavoriteUploadIamge = () => {
    const navigate = useNavigate()
    const theme = useTheme();
    const param = useParams()
    const [uploadImageId, setUploadImageId] = useState([])
    const [uploadImageData, setUploadImageData] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [activeStep, setActiveStep] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isLoading, setIsLoading] = useState(false);
    const [imageModal, setImageModal] = useState(false)

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStylesWithImage = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: screenSize.width <= 800 ? "100vw" : "100vw",
            height: screenSize.height <= 800 ? "100vh" : "100vh",
            maxHeight: "100vh",
            padding: '0px',
            overflow: 'hidden',
            // position: "relative",
            backgroundColor: screenSize.width < 1030 ? "#000000" : "transparent",
            border: "none"
        },
    };


    const handleNext = (data, galleryIndex) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === data?.property_images?.length - 1
                ? 0
                : (prevSteps[data?.propertyMaster_id] || 0) + 1
        }));
    };

    const handleBack = (data, galleryIndex) => {

        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === 0
                ? data?.property_images?.length - 1
                : (prevSteps[data?.propertyMaster_id] || data?.property_images?.length) - 1
        }))

    };

    const handleStepChange = (data, step) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: step
        }));
    };

    const handleRemoveImage = (index, event) => {
        event.stopPropagation();
        const newUploadImageData = [...uploadImageData];
        newUploadImageData.splice(index, 1);
        uploadImageId.splice(index, 1)
        setUploadImageData(newUploadImageData);
    }

    // const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, } = useDropzone({
    //     accept: '.jpg, .png, .jpeg',
    //     onDrop: (acceptedFile) => {
    //         setIsLoading(true);
    //         let formData = new FormData();
    //         acceptedFile.map((file, index) => {
    //             formData.append("file", file);

    //         });
    //         postMethodWithToken(url.profileImageUpload, formData).then((response) => {
    //             if (response.status === 200 || response.status === 201) {
    //                 let imageListIds = [...uploadImageId];
    //                 let imageListData = [...uploadImageData]
    //                 imageListIds.push(response.data.id)
    //                 imageListData.push({
    //                     file: response.data.file
    //                 })
    //                 setUploadImageId(imageListIds)
    //                 setUploadImageData(imageListData)
    //             }
    //         })
    //             .finally(() => {
    //                 setIsLoading(false);
    //             });
    //     }
    // });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: '.jpg, .png, .jpeg',
        onDrop: (acceptedFiles) => {
            setIsLoading(true);

            acceptedFiles.forEach((file) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const image = new Image();
                    image.src = reader.result;

                    image.onload = () => {
                        EXIF.getData(file, function () {
                            const orientation = EXIF.getTag(this, 'Orientation');
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');
                            let width = image.width;
                            let height = image.height;

                            // Adjust canvas size and orientation
                            // if (orientation === 6 || orientation === 8) {
                            //     canvas.width = height;
                            //     canvas.height = width;
                            // } else {
                            canvas.width = width;
                            canvas.height = height;
                            // }

                            if (orientation === 6) {
                                ctx.drawImage(image, 0, 0, width, height);

                            } else if (orientation === 8) {
                                ctx.rotate(-90 * Math.PI / 180);
                                ctx.drawImage(image, -width, 0, width, height);
                            } else if (orientation === 3) {
                                ctx.rotate(180 * Math.PI / 180);
                                ctx.drawImage(image, -width, -height, width, height);
                            } else {
                                ctx.drawImage(image, 0, 0, width, height);
                            }

                            // Convert canvas back to a Blob
                            canvas.toBlob((blob) => {
                                const correctedFile = new File([blob], file.name, {
                                    type: file.type,
                                });

                                // Prepare FormData
                                const formData = new FormData();
                                formData.append("file", correctedFile);

                                // Upload the file
                                postMethodWithToken(url.profileImageUpload, formData)
                                    .then((response) => {
                                        if (response.status === 200 || response.status === 201) {
                                            let imageListIds = [...uploadImageId];
                                            let imageListData = [...uploadImageData];
                                            imageListIds.push(response.data.id);
                                            imageListData.push({
                                                file: response.data.file,
                                            });
                                            setUploadImageId(imageListIds);
                                            setUploadImageData(imageListData);
                                        }
                                    })
                                    .finally(() => {
                                        setIsLoading(false);
                                    });
                            }, file.type);
                        });
                    };
                };

                reader.readAsDataURL(file);
            });
        },
    });


    useEffect(() => {
        getSteps()
    }, [])

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getSteps = async () => {
        await getMethodWithToken(url.selectlist + param.id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                const allDetailStepFive = response?.data?.detail?.stepFive;
                const newIds = allDetailStepFive.map(value => value.id);
                const newFiles = allDetailStepFive.map(item => item);
                setUploadImageId(prevState => [...prevState, ...newIds]);
                setUploadImageData(prevState => [...prevState, ...newFiles])
                setActiveStep(response?.data?.detail?.stepsCompleted)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    console.log(uploadImageId)

    const handleUploadSubmit = async () => {
        let body = {
            "media": uploadImageId
        }
        await postMethodWithToken(url.uploadMedia + param.id + "/", body).then((response) => {
            if (uploadImageData.length >= 5 && (response.status === 200 || response.status === 201)) {
                navigate(`/Dashboard/FavoritePropertieList/Payment/${response.data.propertyId}`, {
                    state: {
                        paymentMethod: "paymentMethod"
                    }
                })
            }
            else if (uploadImageData.length < 5) {
                toast.error("Please add minimum 5 photos")
            }
        })
    }

    useEffect(() => {
        if (openModal || imageModal) {
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
            }, 0);
        } else {
            document.body.style.overflowY = 'auto';
        }
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, [openModal, imageModal]);

    const handleOpenBack = () => {
        setOpenModal(true)
    }
    const handleCloseBack = () => {
        setOpenModal(false)
    }

    const handleSaveExit = () => {
        navigate(`/Dashboard/homePage`)
    }
    const [detailImage, setDetailImage] = useState("")

    const handleImageModal = (id) => {
        setDetailImage(id)
        setImageModal(true)
    }
    const handleCloseImageModal = () => {
        setImageModal(false)
    }

    const CustomPrevArrow = (props) => {
        const { disabled, onClick } = props;
        return (
            <div
                className="custom-image-upload prev-arrows-image"
                onClick={(e) => {
                    e.stopPropagation(); // Stop event propagation
                    if (!disabled && onClick) onClick();
                }}
            >
                <img src={prevArrowImage} alt="Previous" />
            </div>
        );
    };

    const CustomNextArrow = (props) => {
        const { disabled, onClick } = props;
        return (
            <div
                className="custom-image-upload next-arrows-image "
                onClick={(e) => {
                    e.stopPropagation(); // Stop event propagation
                    if (!disabled && onClick) onClick();
                }}
            >
                <img src={nextArrowImage} alt="Next" />
            </div>
        );
    }
    const [currentSlide, setCurrentSlide] = useState(0);
    var settings = {
        infinite: true,
        dots: true,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        autoplaySpeed: 2000,
        beforeChange: (current, next) => setCurrentSlide(next),
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        initialSlide: +detailImage,
        appendDots: (dots) => (
            <div onClick={(e) => e.stopPropagation()}>{dots}</div>
        )
    };

    // useEffect(() => {
    //     if (imageModal) {
    //         document.body.style.overflowY = 'hidden';
    //     } else {
    //         document.body.style.overflowY = 'auto';
    //     }
    //     return () => {
    //         document.body.style.overflowY = 'auto';
    //     };
    // }, [imageModal]);
    useEffect(() => {
        if (imageModal) {
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
            }, 0);
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [ imageModal]);


    return (
        <>
            <ContainerSection>
                <Row style={{ margin: "0" }} >
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <FavoriteSelect activeStep={activeStep} value={4} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                        <div className="upload-container">
                            <div className="upload-heading">Add some photos of your place</div>
                            <div className="upload-description">
                                <div className="upload-description-value" >Add minimum 5 photos. You can add or remove photos after the listing get published.</div>
                            </div>
                            <div className="uploadPhoto" style={{ display: "flex", justifyContent: uploadImageData?.length === 0 && "center", flexWrap: uploadImageData?.length > 0 && "wrap", gap: uploadImageData?.length > 0 && '10px', border: (uploadImageData?.length === 0 && !isLoading) && "2px dotted #303030" }}>
                                {uploadImageData && uploadImageData.length > 0 && uploadImageData.map((item, index) => {
                                    return (
                                        <div key={index} style={{ position: 'relative' }} className="storeImageBlock d-flex mr-3"
                                            draggable>
                                            <div className="ml-2 mr-3">
                                                <CustomImage image={item?.file} onClick={() => handleImageModal(index)} />
                                                <img src={Cross} alt="Remove" style={{ position: 'absolute', top: '5px', right: '5px', cursor: 'pointer' }} onClick={(event) => handleRemoveImage(index, event)} />
                                            </div>
                                        </div>
                                    );
                                })}

                                <div className="storeImageBlock d-flex">
                                    <div className="imageUpload" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                            <p>Drop the files here ...</p>
                                        ) : (
                                            uploadImageData.length > 0 ?
                                                <div className="uploadInfotwo" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {isLoading ?
                                                        <div className="update-pick">
                                                            <div className="loader-add"></div>
                                                        </div>
                                                        :
                                                        <>
                                                            <div>
                                                                <div className="uploadImagetwo"><img width={screenWidth < 768 ? '10px' : '24px'} height={screenWidth < 768 ? '10px' : '24px'} src={Add} /></div>
                                                            </div>
                                                            <div>
                                                                <div className="uploadTexttwo">Add more images</div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                :
                                                uploadImageData?.length == 0 &&
                                                    isLoading ?
                                                    <div className="update-pick-loader">
                                                        <div className="loader"></div>
                                                    </div>
                                                    :
                                                    uploadImageData?.length == 0 &&
                                                    <div style={{ cursor: "pointer" }} className="uploadInfo">
                                                        <div>
                                                            <div className="uploadImage"><img width={screenWidth < 768 ? '48px' : '80px'} height={screenWidth < 768 ? '48px' : '80px'} src={uploadIamgeIcon} /></div>
                                                        </div>
                                                        <div>
                                                            <div className="uploadText">Drag & drop your photo here</div>
                                                        </div>
                                                        <div>
                                                            <div className="uploadTextDes">Add minimum of 5 photos</div>
                                                        </div>
                                                        <div>
                                                            <div className="uploadTextDes" style={{ marginTop: "70px" }}>OR</div>
                                                        </div>
                                                        <div>
                                                            <div className="uploadTextDesLast">Browser photos from your device</div>
                                                        </div>
                                                    </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-md-flex d-none buttons" style={{ margin: "20px 0 20px 30px", alignItems: "center" }} >
                            <div className="backButton-upload">
                                <ContinueButton className="back-list" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={handleOpenBack} fontSize={"20px"} fontWeight={"400"} />
                            </div>
                            <div className="d-flex justify-content-end">
                                <div style={{ marginRight: "50px" }}>
                                    <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                                <div className="saveButton-upload">
                                    <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleUploadSubmit()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                            </div>
                        </div>
                        <div className="d-md-none d-flex justify-content-between mt-3 mb-3">
                            <div style={{ display: "flex", alignItems: "center" }} className='saveExit-upload'>
                                <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={screenWidth < 770 ? '140px' : '200px'} fontSize={screenWidth < 770 ? '14px' : '20px'} fontWeight={"400"} />
                            </div>
                            <div className='saveButton-mobile-upload'>
                                <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleUploadSubmit()} width={screenWidth < 770 ? '140px' : '200px'} fontSize={screenWidth < 770 ? '14px' : '20px'} fontWeight={"400"} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </ContainerSection>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="d-flex justify-content-center">
                    <div className="back-heading">Are you sure you want to back?</div>
                </div>
                <div className="d-flex justify-content-center" >
                    <div style={{ marginTop: "10px" }}>All the changes you will made will be discarded</div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex" style={{ marginTop: "50px" }}>
                        <div className="stay-heading" onClick={handleCloseBack}>Stay on this page</div>
                        <div>
                            <ContinueButton text="Go back" color={'#fff'} onClick={() => navigate(-1)} width={"210px"} borderRadius={"4px"} />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={imageModal} style={customStylesWithImage} overlayClassName="myoverlay">
                <Row>
                    <Col>
                        <div className="modal-upload-image-section" onClick={(e) => {
                            if (screenSize.width > 1030 && !e.target.closest('.slick-dots')) {
                                handleCloseImageModal();
                            }
                        }}>
                            <div style={{ position: "absolute", cursor: 'pointer', top: screenSize.width < 575 ? "50px" : "10px", right: "10px", zIndex: "999" }}>
                                <img
                                    src={Cross}
                                    alt="Remove"
                                    // style={{ cursor: 'pointer' }}
                                    onClick={handleCloseImageModal}
                                />
                            </div>
                            <Slider {...settings} style={{ width: "100%", zIndex: "99" }} >
                                {uploadImageData && uploadImageData.length > 0 && uploadImageData.map((step, index) => {
                                    return (
                                        <>
                                            <div key={index} style={{ position: 'relative' }}>
                                                <CustomImageModal>
                                                    <img src={step?.file} alt={step?.label} />
                                                </CustomImageModal>

                                            </div>

                                        </>
                                    )
                                })}
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default FavoriteUploadIamge
