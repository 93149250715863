import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../../Components/commonComponents/navbar/Navbar'
import Sidebar from '../../Components/commonComponents/sidebar/Sidebar'
import UserProfile from '../UserProfile/UserProfile'
import FavoritePropertiesListing from '../FavoritedProperties/FavoritePropertiesListing/FavoritePropertiesListing'
import ListingCard from '../MyListings/ListingCard/ListingCard'
import ListingManagerList from '../ListingManagers/ListingManager/ListingManagerList/ListingManagerList'
import SubscriptionPlanList from '../ManageSubscription/SubscriptionPlanList/SubscriptionPlanList'
import TenantManagerList from '../TenantManager/TenantManagerList/TenantManagerList'
import PaymentCard from '../PaymentOption/PaymentCard/PaymentCard'
import ChatList from '../ChatModule/ChatListFederal/ChatList'
import "./Dashboard.scss"
import FindPeopleTab from '../FindPeople/FindPeopleTab/FindPeopleTab'
import SideBarMobile from '../../Components/commonComponents/SideBarMobile/SideBarMobile'


const Dashboard = () => {
  const params = useParams()
  const [myContactModal, setMyContactModal] = useState(false)
  const [mobileModal, setMobileModal] = useState(false)
  const [contactModal, setContactModal] = useState(false)
  const [toogleSideModal, setToggleSideModal] = useState(false)
  const [imageListModal, setImageListModal] = useState(false)

  const handleGetOpenModal = (data) => {
    setMyContactModal(data)
  }

  const handleGetModal = (value = false) => {
    setMobileModal(value)
  }
  const handleFilterModal = (val) => {
    setContactModal(val)
  }

  const handleToggleButton = (val) => {
    setToggleSideModal(val)
  }

  const aboutRef = useRef(null);
  const scrollToSection = (sectionId) => {
    const aboutUsPosition = aboutRef.current.getBoundingClientRect().top + window.scrollY;
    if (sectionId === 'about' && aboutRef.current) {
      window.scrollTo({
        top: aboutUsPosition - 0,  // Offset by 200px
        behavior: 'smooth'           // Smooth scrolling
      });
    }
  };

  const handleImageListModal = (val) => {
    setImageListModal(val)
  }
  return (
    <>
      <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
        <Navbar getOpenModal={(val) => handleGetOpenModal(val)} getFilterModal={(val) => handleFilterModal(val)} handleToggleButton={(val) => handleToggleButton(val)} imageListModal={imageListModal} />
        <div className='d-flex'>
          <div className="Sidebar_control side-resposne">
            <Sidebar />
          </div>
          <div className={params.value === "PaymentOptions" ? "Sidebar_control side-value-pay" : params.value === "ListingManagers" ? "Sidebar_control side-value-list" : params.value === "Chats" ? "Sidebar_control side-value-list" : params.value === "TenantManagement" ? "Sidebar_control side-value-list" : params.value === "FindPeople" ? "Sidebar_control side-value-list" : params.value === "FavoritedProperties" ? "Sidebar_control side-value-list-mobile" : params.value === "MyListings" ? "Sidebar_control side-value-my-list" : "Sidebar_control side-value-component"}>
            <div
              style={{
                display: (params.value !== "PaymentOptions" && params.value !== "ListingManagers" && params.value !== "TenantManagement" && params.value !== "Chats" && params.value !== "userProfile" && params.value !== "ManageSubscription" && params.value !== "FindPeople" && params.value !== "FavoritedProperties") && 'inline-block',
                marginTop: '60px',
                marginBottom: (params.value !== "Chats" && params.value !== "MyListings" && params.value !== "FindPeople" && '5.5vh'),
                // marginLeft: "2px"
              }}
            >
              {
                params.value === "userProfile" &&
                <UserProfile />
              }
              {
                params.value === "FavoritedProperties" &&
                <FavoritePropertiesListing mobileModal={mobileModal} contactModal={contactModal} handleImageListModal={(val) => handleImageListModal(val)} />
              }
              {
                params.value === "FindPeople" &&
                <FindPeopleTab myContactModal={myContactModal} mobileModal={mobileModal} contactModal={contactModal} handleImageListModal={(val) => handleImageListModal(val)} />
              }
              {params.value === "MyListings" &&
                <ListingCard myContactModal={myContactModal} mobileModal={mobileModal} handleImageListModal={(val) => handleImageListModal(val)} />
              }
              {params.value === "ListingManagers" &&
                <ListingManagerList contactModal={contactModal} mobileModal={mobileModal} handleImageListModal={(val) => handleImageListModal(val)} />
              }
              {
                params.value === "Chats" &&
                <ChatList />
              }
              {
                params.value === "TenantManagement" &&
                <TenantManagerList handleImageListModal={(val) => handleImageListModal(val)} />
              }
              {
                params.value === "ManageSubscription" &&
                <SubscriptionPlanList />
              }
              {
                params.value === "PaymentOptions" &&
                <PaymentCard />
              }

            </div>
          </div>
        </div>
        <div className="Sidebar_control side-mobile-responsive">
          <SideBarMobile getModal={(val) => handleGetModal(val)} toogleSideModal={toogleSideModal} imageListModal={imageListModal} />
        </div>

      </div >
    </>
  )
}

export default Dashboard
