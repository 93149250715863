import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Container } from 'react-bootstrap';
import FavoriteSelect from "../FavoriteSelect/FavoriteSelect";
import RoomIcon from "../../../../assets/images/favoriteProperty/cil_room.png"
import Home from "../../../../assets/images/favoriteProperty/home.svg"
import Town from "../../../../assets/images/favoriteProperty/town.svg"
import Condo from "../../../../assets/images/favoriteProperty/condo.svg"
import "./FavoritePropertyList.scss"
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton"
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import url from "../../../../utils/services/urls.json";
import { toast } from "react-toastify";
import Modal from "react-modal"
import { navigateLogin } from '../../../../utils/services/common';


const ContainerSection = styled.div`
font-family: Proxima Nova;
`
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "700px",
        height: "230px",
    },
};

const FavoritePropertyList = () => {
    const navigate = useNavigate()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [selectList, setSelectList] = useState([])
    const [proId, setProId] = useState(localStorage.getItem("PropertyType") !== null ? localStorage.getItem("PropertyType") : "");
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        getPropertyDetail()
    }, [])

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getPropertyDetail = async () => {
        await getMethodWithToken(url.selectList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                response?.data.map(function (item) {
                    if (item?.[1] === "property_type") {
                        setSelectList(item?.[0])
                    }
                })
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleSelect = (value) => {
        setProId(value);
        localStorage.setItem("PropertyType", value);
    }

    const handleSubmitSelect = async () => {
        let body = {
            // "masterUser": uesrId,
            "propertyType": proId
        }
        await postMethodWithToken(url.postSelectList, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/Dashboard/FavoritePropertieList/SelectBasic/${response.data.propertyId}/${proId}`)
            }
            else {
                toast.error(response?.data?.error?.propertyType?.[0])
            }
        })
    }

    const handleSaveExit = async () => {
        // let body = {
        //     "masterUser": uesrId,
        //     "propertyType": proId,
        // }
        // await postMethodWithToken(url.postSelectList, body).then((response) => {
        //     if (response.status === 200 || response.status === 201) {
        //         navigate("/Dashboard/homePage");
        //     } else {
        //         toast.error("Please Select one field")
        //     }
        // })
        navigate("/Dashboard/homePage");
    }

    useEffect(() => {
        if (openModal) {
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
            }, 0);
        } else {
            document.body.style.overflowY = 'auto';
        }
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, [openModal]);

    const handleOpenBack = () => {
        setOpenModal(true)
    }
    const handleCloseBack = () => {
        setOpenModal(false)
    }

    return (
        <div className='propertyList' style={{ overflowX: 'hidden' }}>
            <ContainerSection>
                <Container fluid>
                    <Row style={{ margin: 0 }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <FavoriteSelect value={0} />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={7} className="select-list">
                            <div className='d-md-none d-flex justify-content-start mobile-room-value' style={{ visibility: +proId !== '' ? 'visible' : 'hidden' }} >Is your place a</div>
                            <div className='list'>

                                {selectList && selectList.length > 0 && selectList.map((item, index) => {
                                    return (
                                        <>
                                            <div className='value-select'>
                                                <div className="room-value d-md-block d-none" style={{ visibility: +proId === item.id ? 'visible' : 'hidden' }} >Is your place a</div>
                                                <div className='d-block mobile-room-value'>

                                                    <div className="room-select" onClick={() => handleSelect(item.id)} style={{ border: proId == item.id ? "2px solid #2F86D1" : "1px solid #C7C7C7" }}  >
                                                        <div>
                                                            <img src={index === 0 ? RoomIcon : index === 1 ? Home : index === 2 ? Town : Condo} alt="" className='room-image' />
                                                        </div>
                                                        <div className="room-heading" style={{ color: proId === item.id ? "#2F86D1" : "rgb(136 132 132)" }} >{item.name}</div>
                                                    </div>
                                                </div>


                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                    <div className="d-md-flex  buttons d-none d-none" style={{ alignItems: "center" }} >
                        <div className='backButton-ipaid'>
                            <ContinueButton backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={handleOpenBack} fontSize={"20px"} fontWeight={"400"} />
                        </div>
                        <div className="d-flex justify-content-end justify-content-xs-center justify-content-sm-center">
                            <div style={{ display: "flex", alignItems: "center" }} className='saveButton'>
                                <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={screenWidth < 768 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} className="saveExitButton" />
                            </div>
                            <div style={{ fontFamily: "Proxima_nova_reg" }} className='saveButton-continue'>
                                <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleSubmitSelect()} width={screenWidth < 768 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} className="saveExitButton" />
                            </div>
                        </div>
                    </div>
                    <div className="d-md-none d-flex justify-content-between mt-3 mb-3 mobile-buttons">
                        <div style={{ display: "flex", alignItems: "center" }} className='saveButton'>
                            <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={screenWidth < 768 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} />
                        </div>
                        <div style={{ fontFamily: "Proxima_nova_reg" }} className='saveButton-mobile'>
                            <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleSubmitSelect()} width={screenWidth < 768 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} />
                        </div>
                    </div>
                </Container>
            </ContainerSection>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="d-flex justify-content-center">
                    <div className="back-heading">Are you sure you want to back?</div>
                </div>
                <div className="d-flex justify-content-center" >
                    <div style={{ marginTop: "10px" }}>All the changes you will made will be discarded</div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex" style={{ marginTop: "50px" }}>
                        <div className="stay-heading" onClick={handleCloseBack}>Stay on this page</div>
                        <div>
                            <ContinueButton text="Go back" color={'#fff'} onClick={() => navigate(-1)} width={"210px"} borderRadius={"4px"} />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default FavoritePropertyList
