import React from 'react'
import styled from 'styled-components';
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { useNavigate, useLocation } from 'react-router-dom';
import "./Sidebar.scss";

const StyledSidebar = styled.div`
background: #ffffff;
  top: 0;
  z-index: -1;
  padding: 20px 20px 50px 40px;
  margin: 80px 20px 30px 40px;
  // overflow-y: auto;
  
  @media only screen and (min-width: 1311px) and (max-width: 1481px) {
    margin: 80px 15px 40px 30px;
    padding: 10px 20px 55px 20px;
  }
  
  @media only screen and (min-width: 1114px) and (max-width: 1312px) {
    padding: 5px 5px 60px 5px;
    margin: 80px 20px 30px 10px;
  }
  
  @media only screen and (min-width: 576px) and (max-width: 1113px) {
    padding: 5px 0 70px 0;
    margin: 80px 20px 30px 10px;
  }

   @media only screen and (max-width: 576px) {
    padding: 5px 0 70px 0;
    margin: 80px 20px 30px 0px;
  }

  
`;

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();


  return (
    <>
      <StyledSidebar>
        <div className="side-desktop">
          <Navigation
            activeItemId={location.pathname === "/Dashboard/ListingManagers/AddManager" ? "/Dashboard/ListingManagers" : location.pathname === "/Dashboard/ManageSubscription/change/plan" ? "/Dashboard/ManageSubscription" : location.pathname.includes('/Dashboard/ListingManagers/list/detail') ? "/Dashboard/ListingManagers" : location.pathname}
            onSelect={({ itemId }) => {
              localStorage.removeItem("PageNo");
              navigate(itemId);
              localStorage.setItem("findListTab", 0)
              localStorage.setItem("myListTab",0)

            }}
            items={[
              {
                title: "Profile",
                itemId: "/Dashboard/userProfile",
                elemBefore: () => (
                  <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.333008 28.3333C0.333008 22.4422 5.10863 17.6666 10.9997 17.6666C16.8907 17.6666 21.6663 22.4422 21.6663 28.3333H18.9997C18.9997 23.915 15.4179 20.3333 10.9997 20.3333C6.58139 20.3333 2.99967 23.915 2.99967 28.3333H0.333008ZM10.9997 16.3333C6.57967 16.3333 2.99967 12.7533 2.99967 8.33325C2.99967 3.91325 6.57967 0.333252 10.9997 0.333252C15.4197 0.333252 18.9997 3.91325 18.9997 8.33325C18.9997 12.7533 15.4197 16.3333 10.9997 16.3333ZM10.9997 13.6666C13.9463 13.6666 16.333 11.2799 16.333 8.33325C16.333 5.38659 13.9463 2.99992 10.9997 2.99992C8.05301 2.99992 5.66634 5.38659 5.66634 8.33325C5.66634 11.2799 8.05301 13.6666 10.9997 13.6666Z" fill={location && location.pathname === "/Dashboard/userProfile" ? "#2975CA" : "#919CA5"} />
                  </svg>

                ),
              },
              {
                title: "Favorites",
                itemId: "/Dashboard/FavoritedProperties",
                elemBefore: () => (
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="25" viewBox="0 0 28 25" fill="none">
                    <path d="M14.0017 2.03804C17.1337 -0.773332 21.9737 -0.679999 24.9905 2.34315C28.0074 5.36629 28.111 10.1827 25.3051 13.324L14.0002 24.6467L2.6955 13.324C-0.110277 10.1827 -0.00539684 5.35868 3.01014 2.34315C6.02908 -0.675799 10.8606 -0.777506 14.0017 2.03804ZM23.103 4.2268C21.1042 2.22392 17.8771 2.14268 15.783 4.02249L14.0029 5.62032L12.2218 4.02375C10.1216 2.14129 6.9004 2.22411 4.89575 4.22876C2.90943 6.21508 2.80971 9.3964 4.64023 11.4976L14.0002 20.8724L23.3605 11.4976C25.1917 9.3956 25.0923 6.22033 23.103 4.2268Z" fill={location && location.pathname === "/Dashboard/FavoritedProperties" ? "#2975CA" : "#919CA5"} />
                  </svg>
                ),
              },
              {
                title: "Find People",
                itemId: "/Dashboard/FindPeople",
                elemBefore: () => (
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="29" viewBox="0 0 25 29" fill="none">
                    <path d="M18.7882 19.8793L19.0228 20.1153L19.2595 19.8793C20.4311 18.7077 22.3306 18.7077 23.5022 19.8793C24.6738 21.0509 24.6738 22.9504 23.5022 24.122L19.0239 28.6003L14.5455 24.122C13.3739 22.9504 13.3739 21.0509 14.5455 19.8793C15.7171 18.7077 17.6166 18.7077 18.7882 19.8793ZM11.0002 17.6673V20.334C6.58188 20.334 3.00016 23.9157 3.00016 28.334H0.333496C0.333496 22.5541 4.93062 17.848 10.6679 17.6724L11.0002 17.6673ZM11.0002 0.333984C15.4202 0.333984 19.0002 3.91398 19.0002 8.33398C19.0002 12.6536 15.5811 16.1708 11.3002 16.3285L11.0002 16.334C6.58016 16.334 3.00016 12.754 3.00016 8.33398C3.00016 4.01444 6.41928 0.497144 10.7002 0.339504L11.0002 0.333984ZM11.0002 3.00065C8.05292 3.00065 5.66683 5.38674 5.66683 8.33398C5.66683 11.2812 8.05292 13.6673 11.0002 13.6673C13.9474 13.6673 16.3335 11.2812 16.3335 8.33398C16.3335 5.38674 13.9474 3.00065 11.0002 3.00065Z" fill={location && location.pathname === "/Dashboard/FindPeople" ? "#2975CA" : "#919CA5"} />
                  </svg>
                )
              },
              {
                title: "Chats",
                itemId: "/Dashboard/Chats",
                elemBefore: () => (
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M8.66699 12.6667C9.04477 12.6667 9.36144 12.539 9.61699 12.2834C9.87255 12.0279 10.0003 11.7112 10.0003 11.3334C10.0003 10.9556 9.87255 10.639 9.61699 10.3834C9.36144 10.1279 9.04477 10.0001 8.66699 10.0001C8.28921 10.0001 7.97255 10.1279 7.71699 10.3834C7.46144 10.639 7.33366 10.9556 7.33366 11.3334C7.33366 11.7112 7.46144 12.0279 7.71699 12.2834C7.97255 12.539 8.28921 12.6667 8.66699 12.6667ZM14.0003 12.6667C14.3781 12.6667 14.6948 12.539 14.9503 12.2834C15.2059 12.0279 15.3337 11.7112 15.3337 11.3334C15.3337 10.9556 15.2059 10.639 14.9503 10.3834C14.6948 10.1279 14.3781 10.0001 14.0003 10.0001C13.6225 10.0001 13.3059 10.1279 13.0503 10.3834C12.7948 10.639 12.667 10.9556 12.667 11.3334C12.667 11.7112 12.7948 12.0279 13.0503 12.2834C13.3059 12.539 13.6225 12.6667 14.0003 12.6667ZM19.3337 12.6667C19.7114 12.6667 20.0281 12.539 20.2837 12.2834C20.5392 12.0279 20.667 11.7112 20.667 11.3334C20.667 10.9556 20.5392 10.639 20.2837 10.3834C20.0281 10.1279 19.7114 10.0001 19.3337 10.0001C18.9559 10.0001 18.6392 10.1279 18.3837 10.3834C18.1281 10.639 18.0003 10.9556 18.0003 11.3334C18.0003 11.7112 18.1281 12.0279 18.3837 12.2834C18.6392 12.539 18.9559 12.6667 19.3337 12.6667ZM0.666992 27.3334V3.33341C0.666992 2.60008 0.928103 1.9723 1.45033 1.45008C1.97255 0.927859 2.60033 0.666748 3.33366 0.666748H24.667C25.4003 0.666748 26.0281 0.927859 26.5503 1.45008C27.0725 1.9723 27.3337 2.60008 27.3337 3.33341V19.3334C27.3337 20.0667 27.0725 20.6945 26.5503 21.2167C26.0281 21.739 25.4003 22.0001 24.667 22.0001H6.00033L0.666992 27.3334ZM4.86699 19.3334H24.667V3.33341H3.33366V20.8334L4.86699 19.3334Z" fill={location && location.pathname === "/Dashboard/Chats" ? "#2975CA" : "#919CA5"} />
                  </svg>
                ),
              },
              {
                title: "My Listings",
                itemId: "/Dashboard/MyListings",
                elemBefore: () => (
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M6.66667 1.33341H24V4.00008H6.66667V1.33341ZM0 0.666748H4V4.66675H0V0.666748ZM0 10.0001H4V14.0001H0V10.0001ZM0 19.3334H4V23.3334H0V19.3334ZM6.66667 10.6667H24V13.3334H6.66667V10.6667ZM6.66667 20.0001H24V22.6667H6.66667V20.0001Z" fill={location.pathname === "/Dashboard/MyListings" ? "#2975CA" : "#919CA5"} />
                  </svg>
                )
              },
              {
                title: "Listing Managers",
                itemId: "/Dashboard/ListingManagers",
                elemBefore: () => (
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 24 30" fill="none">
                    <path d="M1.04409 2.76789L12 0.333252L22.9559 2.76789C23.566 2.90347 24 3.44455 24 4.06948V17.3851C24 20.0599 22.6632 22.5578 20.4376 24.0415L12 29.6666L3.5624 24.0415C1.33681 22.5578 0 20.0599 0 17.3851V4.06948C0 3.44455 0.43404 2.90347 1.04409 2.76789ZM2.66667 5.13904V17.3851C2.66667 19.1683 3.55787 20.8335 5.0416 21.8227L12 26.4617L18.9584 21.8227C20.4421 20.8335 21.3333 19.1683 21.3333 17.3851V5.13904L12 3.06497L2.66667 5.13904ZM12 13.6666C10.1591 13.6666 8.66667 12.1742 8.66667 10.3333C8.66667 8.49231 10.1591 6.99992 12 6.99992C13.8409 6.99992 15.3333 8.49231 15.3333 10.3333C15.3333 12.1742 13.8409 13.6666 12 13.6666ZM6.03661 20.3333C6.36825 17.3333 8.91163 14.9999 12 14.9999C15.0884 14.9999 17.6317 17.3333 17.9633 20.3333H6.03661Z" fill={(location.pathname === "/Dashboard/ListingManagers" || location.pathname === "/Dashboard/ListingManagers/AddManager" || location.pathname.includes('/Dashboard/ListingManagers/list/detail')) ? "#2975CA" : "#919CA5"} />
                  </svg>
                )
              },
              {
                title: "Tenant Management",
                itemId: "/Dashboard/TenantManagement",
                elemBefore: () => (
                  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5778 1.89715L14.1535 1.5128C14.1487 1.50871 14.1441 1.5043 14.1398 1.4996C13.7716 1.17813 13.2918 1 12.7942 1C12.2965 1 11.8167 1.17813 11.4485 1.4996L11.4348 1.5128L1.44605 10.5712C1.2423 10.751 1.07966 10.9696 0.968419 11.2129C0.857179 11.4563 0.799763 11.7191 0.799805 11.9849V23.0798C0.799805 23.5891 1.01052 24.0775 1.38559 24.4376C1.76066 24.7977 2.26937 25 2.7998 25H22.7998C23.3302 25 23.8389 24.7977 24.214 24.4376C24.5891 24.0775 24.7998 23.5891 24.7998 23.0798V12.6457C24.1932 13.0709 23.5208 13.4086 22.8008 13.6404L22.7998 23.0798H2.7998V11.9849L2.81355 11.9729L12.7998 2.91812L13.5731 3.61899C13.8314 2.99849 14.1708 2.42015 14.5778 1.89715Z" fill={location.pathname === "/Dashboard/TenantManagement" ? "#2975CA" : "#919CA5"} />
                  </svg>
                )
              },
              {
                title: "Payment Options",
                itemId: "/Dashboard/PaymentOptions",
                elemBefore: () => (
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="24" viewBox="0 0 28 24" fill="none">
                    <path d="M2.00618 0H26.0062C26.7426 0 27.3395 0.59696 27.3395 1.33333V22.6667C27.3395 23.4031 26.7426 24 26.0062 24H2.00618C1.26981 24 0.672852 23.4031 0.672852 22.6667V1.33333C0.672852 0.59696 1.26981 0 2.00618 0ZM24.6729 10.6667H3.33952V21.3333H24.6729V10.6667ZM24.6729 8V2.66667H3.33952V8H24.6729ZM16.6729 16H22.0062V18.6667H16.6729V16Z" fill={location.pathname === "/Dashboard/PaymentOptions" ? "#2975CA" : "#919CA5"} />
                  </svg>
                )
              },
              {
                title: "Manage Subscription",
                itemId: "/Dashboard/ManageSubscription",
                elemBefore: () => (
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M14.0003 3.33317C10.3356 3.33317 7.10043 5.18152 5.17956 7.99984H8.66699V10.6665H0.666992V2.6665H3.33366V5.99898C5.76541 2.76205 9.63718 0.666504 14.0003 0.666504C21.3641 0.666504 27.3337 6.63604 27.3337 13.9998H24.667C24.667 8.1088 19.8914 3.33317 14.0003 3.33317ZM3.33366 13.9998C3.33366 19.8909 8.10928 24.6665 14.0003 24.6665C17.6651 24.6665 20.9002 22.8181 22.8211 19.9998H19.3337V17.3332H27.3337V25.3332H24.667V22.0006C22.2353 25.2376 18.3635 27.3332 14.0003 27.3332C6.63653 27.3332 0.666992 21.3636 0.666992 13.9998H3.33366Z" fill={((location.pathname === "/Dashboard/ManageSubscription" || location.pathname === "/Dashboard/ManageSubscription/change/plan")) ? "#2975CA" : "#919CA5"} />
                  </svg>
                )
              }
            ]}
          />

        </div>
      </StyledSidebar>
    </>
  )
}

export default Sidebar
