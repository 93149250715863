import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import { styled } from '@mui/system';
// import { Tabs, TabsList as BaseTabsList, TabPanel as BaseTabPanel, Tab as BaseTab, tabClasses } from '@mui/base';
// import { buttonClasses } from '@mui/base/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { InputAdornment, } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import "./FindPeopleTab.scss";
import { getMethodWithToken, postMethodWithToken, postMethodWithTokenWithBody } from '../../../utils/services/apis';
import url from "../../../utils/services/urls.json"
import FindPeopleList from '../FindPeopleList/FindPeopleList';
import MyFriendList from '../MyFriendList/MyFriendList';
import ReceiveList from '../ReceiveList/ReceiveList';
import SendRequest from '../SendRequest/SendRequest';
import PhoneIcon from "../../../assets/images/call.svg"
import Mail from "../../../assets/images/mail.svg"
import Message from "../../../assets/images/listingManager/add-manager.svg"
import MessageBox from "../../../assets/images/listingManager/sms.svg"
import requestSend from "../../../assets/images/listingManager/request-send.svg"
import Reject from "../../../assets/images/listingManager/reject.svg"
import Accept from "../../../assets/images/listingManager/accept.svg"
import { toast } from 'react-toastify';
import DefaultImage from "../../../assets/images/default-image.svg";
import ArrowBackChat from "../../../assets/images/arrow-back-chat.svg";
import { useMediaQuery } from '@mui/material';
import { navigateLogin } from '../../../utils/services/common';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// const Tab = styled(BaseTab)`
//   font-family: 'IBM Plex Sans', sans-serif;
//   color: #A0A0A0;
//   cursor: pointer;
//   font-size: 0.875rem;
//   font-weight: bold;
//   background-color: transparent;
//   width: 100%;
//   line-height: 1.5;
//   padding: 14px 12px;
//   border: none;
//   border-right: 1px solid black;
//   display: flex;
//   justify-content: center;

//   &.${tabClasses.selected} {
//     background-color: #0075CA;
//     color: #FFFFFF;
//   }

//   &.${buttonClasses.disabled} {
//     opacity: 0.5;
//     cursor: not-allowed;
//   }

//   @media (max-width: 882px) {
//       border: none;
//     }
// `;

// const TabPanel = styled(BaseTabPanel)`
//   width: 100%;
//   font-family: 'IBM Plex Sans', sans-serif;
//   font-size: 0.875rem;
// `;

// const TabsList = styled(BaseTabsList)(
//     ({ theme }) => `
//     width: 700px;
//     background-color: #FFFFFF;
//     margin-bottom: 0px;
//     margin-top: 20px;
//     border: 1px solid black;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     align-content: space-between;
//     position: fixed;
//     margin-bottom: 40px;


//     @media (max-width: 882px) {
//       flex-direction: column;
//       padding: 10px;
//       width: 78%;
//     }

//     @media (min-width: 882px) {
//       flex-direction: row; /* Horizontal on large screens */
//     }
//   `,
// );

const FindPeopleTab = ({ myContactModal, mobileModal, contactModal,handleImageListModal }) => {
    const navigate = useNavigate()
    const [value, setValue] = useState(localStorage.getItem("findListTab") ? +localStorage.getItem("findListTab") : 0);
    const [searchInput, setSearchInput] = useState("")
    const [searchInputMyFriend, setSearchInputMyFriend] = useState("")
    const [checked, setChecked] = useState(false)
    const [searchListPeople, setSearchListPeople] = useState([])
    const [myFirendList, setMyFriendList] = useState([])
    const [receivedList, setReceivedList] = useState([])
    const [sendRequest, setSendRequest] = useState([])
    const [searchId, setSearchId] = useState("")
    const [searchMyListId, setSearchMyListId] = useState("")
    const [listDetail, setListDetail] = useState(false)
    const [friendDetail, setFriendDetail] = useState(false)
    const [receiveDetail, setReceiveDetail] = useState(false)
    const [sendRequestDetail, setsendRequestDetail] = useState(false)
    const [findDetailList, setFindDetailList] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem("findListTab", newValue)
        setListDetail(false)
        setFriendDetail(false)
        setFriendDetail(false)
        setReceiveDetail(false)
        setSearchId("")
        setSearchMyListId("")
    }

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value)
    }

    const handleCheck = (event) => {
        setChecked(event.target.checked)
    }
    useEffect(() => {
        if (value === 0) {
            getFindPeopleList()
        }
    }, [checked, value])

    const getFindPeopleList = async () => {
        let urls;
        if (checked) {
            urls = url.findPeopleList + "?is_organization=yes" + "&search=" + searchInput;
        } else {
            urls = url.findPeopleList + "?is_organization=" + "&search=" + searchInput;
        }
        await getMethodWithToken(urls).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setSearchListPeople(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const getRandomNumberProperty = (number) => {
        return Math.floor(Math.random() * number);
    };

    const handleSearchId = (data) => {
        setSearchId(data?.id)
        setListDetail(true)
    }
    const handleSearchIdFriend = (data) => {
        setSearchId(data?.id)
        setFriendDetail(true)
    }
    const handleSearchIdRecieve = (data) => {
        setSearchId(data?.id)
        setReceiveDetail(true)

    }
    const handleSearchIdSendRequest = (data) => {
        setSearchId(data?.id)
        setsendRequestDetail(true)
    }
    const handleSearchValue = () => {
        getFindPeopleList()
    }


    useEffect(() => {
        if (searchId) {
            getFindPeopleListDetail()
        }
    }, [searchId])

    const getFindPeopleListDetail = async () => {
        await getMethodWithToken(url.findPeopleListData + searchId + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                setFindDetailList(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }


    useEffect(() => {
        if (value === 1) {
            getMyFrinedList()
        } else if (value === 2) {
            getReceivedList()
        } else if (value === 3) {
            getSendRequestList()
        }
    }, [value])

    const getMyFrinedList = async () => {
        await getMethodWithToken(url.myFirnedList + "?search=" + searchInputMyFriend).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMyFriendList(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const getReceivedList = async () => {
        await getMethodWithToken(url.receivedList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setReceivedList(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const getSendRequestList = async () => {
        await getMethodWithToken(url.sendRequestList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setSendRequest(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleSearchInputMyFriendChange = (event) => {
        setSearchInputMyFriend(event?.target?.value)
    }
    const handleSearchMyFriendValue = () => {
        getMyFrinedList()
    }
    const handleSearchMyListId = (data) => {
        setSearchMyListId(data?.id)
        setListDetail(true)
    }

    const handleRequestSend = async () => {
        let body = {
            "receiver": findDetailList?.id
        }
        await postMethodWithToken(url.findPeopleListData, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.message)
                getFindPeopleListDetail()
            } else {
                toast.error(response?.data?.message)
            }
        })
    }

    const handleAcceptRequest = async () => {
        await postMethodWithTokenWithBody(url.postAcceptRequest + "request/" + findDetailList?.friend_request?.friend_request_id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.message)
            }
        })
    }
    const handleModalValue = (val) => {
        setModalShow(val)
    }

    const handleArrowchange = () => {
        setListDetail(false)
        setSearchId("")
        setFriendDetail(false)
        setReceiveDetail(false)
        setsendRequestDetail(false)

    }
    const handleModalIndex = (index) => {
        setModalShow(index)
    }
    return (
        <>
            <div className="find-people-tab" style={{ background: (!modalShow && !myContactModal && !mobileModal) ? "rgb(242, 242, 247)" : "", zIndex: (!modalShow && !myContactModal && !mobileModal) ? "1" : "" }}>
                <div className="tab-value">
                    <Tabs value={value} onChange={handleChange}  >
                        <Tab label="Find People" {...a11yProps(0)} style={{ textTransform: 'capitalize' }} />
                        <Tab label="My Friends" {...a11yProps(1)} style={{ textTransform: 'capitalize' }} />
                        <Tab label="Received Requests" {...a11yProps(2)} style={{ textTransform: 'capitalize' }} />
                        <Tab label="Sent Requests" {...a11yProps(3)} style={{ textTransform: 'capitalize' }} />
                    </Tabs>
                </div>
            </div>

            <TabPanel value={value} index={0}>
                <Row className="g-2">
                    {(!listDetail || screenSize.width > 1200) &&
                        <Col xs={12} sm={12} md={7} lg={7} xl={4} xxl={4}>
                            <div className="find-people-search">
                                {searchListPeople && searchListPeople.length > 0 &&
                                    <>
                                        <div style={{ position: screenSize.width < 575 && "fixed", width: screenSize.width < 575 && "98%" }}>
                                            <TextField
                                                fullWidth
                                                name="search"
                                                id="outlined-basic"
                                                label="Search..."
                                                variant="outlined"
                                                value={searchInput}
                                                onChange={(e) => handleSearchInputChange(e)}
                                                style={{ background: "#ffffff", zIndex: "0" }}
                                                InputLabelProps={{
                                                    style: { color: "#909090" }
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton>
                                                                <div className="search-btn" onClick={() => handleSearchValue()}>
                                                                    <div>
                                                                        <SearchIcon style={{ color: "white", width: "20px", height: "20px" }} />
                                                                    </div>
                                                                    <div className="d-none d-sm-flex" style={{ color: "white", fontSize: "16px", fontWeight: 500, paddingLeft: "5px" }} >Search</div>
                                                                </div>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </div>
                                        <div className="d-flex mt-4 search-check align-items-end align-items-md-center " style={{ position: screenSize.width < 575 && "fixed", paddingTop: screenSize.width < 575 && "40px" }}>
                                            <FormCheckInput type="checkbox" name="verifyFed" id="verifyFed" style={{ width: screenSize.width < 768 ? "18px" : "20px", height: screenSize.width < 768 ? "18px" : "20px", border: " 1px solid #505050" }} onChange={handleCheck} />
                                            <label className="ps-2 check-list text-start mt-md-1" htmlFor="verifyFed">Search for people only in my organization</label>
                                        </div>

                                    </>
                                }
                                <Row>
                                    <Col >
                                        <div className={searchListPeople && searchListPeople.length > 0 ? "search-find-list" : "manager-card-without-result"}>
                                            {searchListPeople && searchListPeople.length > 0 ?
                                                searchListPeople.map((data, index) => {
                                                    return (
                                                        <div className="d-flex" style={{ paddingLeft: "15px", paddingTop: "10px", paddingBottom: "10px", background: searchId === data.id ? "#D9EFFF" : "", cursor: "pointer", borderBottom: searchId === data.id && "1px solid #40A0E5" }} onClick={() => handleSearchId(data)}>
                                                            <div>
                                                                <img src={data?.profilePic?.file ? data?.profilePic?.file : DefaultImage} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                            </div>
                                                            <div className="find-heading" >
                                                                <div className="find-main-heading">{data?.name}</div>
                                                                {/* <div className="find-description">{data?.orgnization}</div> */}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="result-heading" >No results found</div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    }
                    <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={7}>
                        {listDetail &&
                            <div>
                                <div className="find-people-listing-data">
                                    {screenSize.width < 1200 &&
                                        <img src={ArrowBackChat} alt="back" style={{ cursor: "pointer", marginLeft: "10px" }} onClick={handleArrowchange} />
                                    }
                                    <div className="d-flex justify-content-center listing-data" >
                                        <img src={findDetailList?.profilePic?.file ? findDetailList?.profilePic?.file : DefaultImage} alt="profilePic" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                    </div>
                                    <div className="d-flex justify-content-center ">
                                        <div className="listing-data-heading">{findDetailList?.name}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "13px" }}>
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>

                                    <div className="phone-container" style={{ marginTop: "20px" }}>
                                        {findDetailList?.friend_request === null ?
                                            <div className="msg-box" onClick={() => handleRequestSend()}>
                                                <div style={{ marginRight: "10px" }}>
                                                    <img src={Message} alt="" />
                                                </div>
                                                <div style={{ fontSize: "16px", fontWeight: 500, color: "#ffffff" }}>Add as friend</div>
                                            </div>
                                            :
                                            findDetailList?.friend_request?.accepted === true ?
                                                <div className="msg-request-box">
                                                    <div style={{ marginRight: "10px" }}>
                                                        <img src={requestSend} alt="" />
                                                    </div>
                                                    <div style={{ fontSize: "16px", fontWeight: 500, color: "#0075CA" }}>Added as Friend</div>
                                                </div>
                                                :
                                                <div className="msg-request-box">
                                                    <div style={{ marginRight: "10px" }}>
                                                        <img src={requestSend} alt="" />
                                                    </div>
                                                    <div style={{ fontSize: "16px", fontWeight: 500, color: "#0075CA" }}>Request Sent</div>
                                                </div>

                                        }

                                    </div>

                                    <div className="email-conatiner" style={{ marginTop: "20px" }}>
                                        {findDetailList?.friend_request === null ?
                                            <div className="msg-box" onClick={() => handleRequestSend()}>
                                                <div style={{ marginRight: "10px" }}>
                                                    <img src={Message} alt="" />
                                                </div>
                                                <div style={{ fontSize: "16px", fontWeight: 500, color: "#ffffff" }}>Add as friend</div>
                                            </div>
                                            :
                                            findDetailList?.friend_request?.accepted === true ?
                                                <div className="msg-request-box">
                                                    <div style={{ marginRight: "10px" }}>
                                                        <img src={requestSend} alt="" />
                                                    </div>
                                                    <div style={{ fontSize: "16px", fontWeight: 500, color: "#0075CA" }}>Added as Friend</div>
                                                </div>
                                                :
                                                <div className="msg-request-box">
                                                    <div style={{ marginRight: "10px" }}>
                                                        <img src={requestSend} alt="" />
                                                    </div>
                                                    <div style={{ fontSize: "16px", fontWeight: 500, color: "#0075CA" }}>Request Sent</div>
                                                </div>

                                        }
                                    </div>
                                </div>
                                <div className="responsive-find-list">
                                    <FindPeopleList searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} contactModal={contactModal} mobileModal={mobileModal} handleModalIndex={(index) => handleModalIndex(index)} handleImageListModal={(val)=>handleImageListModal(val)} />
                                </div>
                            </div>

                        }
                    </Col>
                    {listDetail &&
                        <div className="responsive-find-main">
                            <FindPeopleList searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} contactModal={contactModal} mobileModal={mobileModal} handleModalIndex={(index) => handleModalIndex(index)} handleImageListModal={(val)=>handleImageListModal(val)} />
                        </div>
                    }
                </Row>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Row>
                    {(!friendDetail || screenSize.width > 1200) &&
                        <Col xs={12} sm={12} md={7} lg={7} xl={4} xxl={4}>
                            <div className="find-people-search">
                                {myFirendList && myFirendList.length > 0 &&
                                    <div style={{ position: screenSize.width < 575 && "fixed", width: screenSize.width < 575 && "98%" }}>
                                        <TextField
                                            fullWidth
                                            name="search"
                                            id="outlined-basic"
                                            label="Search..."
                                            variant="outlined"
                                            value={searchInputMyFriend}
                                            onChange={(e) => handleSearchInputMyFriendChange(e)}
                                            style={{ background: "#ffffff", zIndex: "0" }}
                                            InputLabelProps={{
                                                style: { color: "#909090" }
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton>
                                                            <div className="search-btn" onClick={() => handleSearchMyFriendValue()}>
                                                                <div>
                                                                    <SearchIcon style={{ color: "white", width: "20px", height: "20px" }} />
                                                                </div>
                                                                <div className="d-none d-sm-flex" style={{ color: "white", fontSize: "16px", fontWeight: 500, paddingLeft: "5px" }} >Search</div>
                                                            </div>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                }
                                <Row>
                                    <Col >
                                        <div className={myFirendList && myFirendList.length > 0 ? "search-my-list" : "manager-card-without-result"}>
                                            {myFirendList && myFirendList.length > 0 ?
                                                myFirendList.map((data, index) => {
                                                    return (
                                                        <div className="d-flex" style={{ paddingLeft: "15px", paddingTop: "10px", paddingBottom: "10px", background: searchId === data.id ? "#D9EFFF" : "", cursor: "pointer", borderBottom: searchId === data.id && "1px solid #40A0E5" }} onClick={() => handleSearchIdFriend(data)}>
                                                            <div>
                                                                <img src={data?.profilePic?.file ? data?.profilePic?.file : DefaultImage} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                            </div>
                                                            <div className="find-heading" >
                                                                <div className="find-main-heading">{data?.name}</div>
                                                                <div className="find-description">{data?.orgnization}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="result-heading" >No results found</div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    }
                    <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={7}>
                        {friendDetail &&
                            <div>
                                <div className="find-people-listing-data">
                                    {screenSize.width < 1200 &&
                                        <img src={ArrowBackChat} alt="back" style={{ cursor: "pointer", marginLeft: "10px" }} onClick={handleArrowchange} />
                                    }
                                    <div className="d-flex justify-content-center listing-data" >
                                        <img src={findDetailList?.profilePic?.file ? findDetailList?.profilePic?.file : DefaultImage} alt="profilePic" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                    </div>
                                    <div className="d-flex justify-content-center ">
                                        <div className="listing-data-heading">{findDetailList?.name}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "13px" }}>
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "20px" }}>
                                        <div className="msg-request-box">
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={requestSend} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#0075CA" }}>Added as Friend</div>
                                        </div>
                                        <div className="msg-box" style={{ border: "1px solid #E0E0E0", background: "#ffffff", color: "#2F86D1", fontSize: "16px", fontWeight: 500, marginLeft: "15px" }} >
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={MessageBox} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#707070" }}>Message</div>
                                        </div>
                                    </div>
                                    <div className="email-conatiner mt-3">
                                        <div className="msg-request-box">
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={requestSend} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#0075CA" }}>Added as Friend</div>
                                        </div>
                                    </div>
                                    <div className="email-conatiner mt-3">
                                        <div className="msg-box" style={{ border: "1px solid #E0E0E0", background: "#ffffff", color: "#2F86D1", fontSize: "16px", fontWeight: 500 }} >
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={MessageBox} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#707070" }}>Message</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="responsive-find-list">

                                    <MyFriendList searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} contactModal={contactModal} mobileModal={mobileModal} handleModalIndex={(index) => handleModalIndex(index)} handleImageListModal={(val)=>handleImageListModal(val)} />
                                </div>

                            </div>
                        }
                    </Col>
                    {friendDetail &&
                        <div className="responsive-find-main">
                            <MyFriendList searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} contactModal={contactModal} mobileModal={mobileModal} handleModalIndex={(index) => handleModalIndex(index)} handleImageListModal={(val)=>handleImageListModal(val)} />
                        </div>
                    }
                </Row>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Row>
                    {(!receiveDetail || screenSize.width > 1200) &&
                        <Col xs={12} sm={12} md={7} lg={7} xl={4} xxl={4}>
                            <div className="find-people-search">
                                <Row>
                                    <Col >
                                        <div className={receivedList && receivedList.length > 0 ? "search-receive-list" : "manager-card-without-result"}>
                                            {receivedList && receivedList.length > 0 ?
                                                receivedList.map((data, index) => {
                                                    return (
                                                        <div className="d-flex" style={{ paddingLeft: "15px", paddingTop: "10px", paddingBottom: "10px", background: searchId === data.id ? "#D9EFFF" : "", cursor: "pointer" }} onClick={() => handleSearchIdRecieve(data)}>
                                                            <div>
                                                                <img src={data?.profilePic?.file ? data?.profilePic?.file : DefaultImage} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                            </div>
                                                            <div className="find-heading" >
                                                                <div className="find-main-heading">{data?.name}</div>
                                                                <div className="find-description">{data?.orgnization}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="result-heading" >No results found</div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    }
                    <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={7}>
                        {receiveDetail &&
                            <div>
                                <div className="find-people-listing-data">
                                    {screenSize.width < 1200 &&
                                        <img src={ArrowBackChat} alt="back" style={{ cursor: "pointer", marginLeft: "10px" }} onClick={handleArrowchange} />
                                    }
                                    <div className="d-flex justify-content-center listing-data" >
                                        <img src={findDetailList?.profilePic?.file ? findDetailList?.profilePic?.file : DefaultImage} alt="profilePic" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                    </div>
                                    <div className="d-flex justify-content-center ">
                                        <div className="listing-data-heading">{findDetailList?.name}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "13px" }}>
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "20px" }}>
                                        <div className="msg-request-box-receive" onClick={() => handleAcceptRequest()}>
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={Accept} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#ffffff" }}>Accept Request</div>
                                        </div>
                                        <div className="msg-box" style={{ border: "1px solid #E0E0E0", background: "#ffffff", color: "#2F86D1", fontSize: "16px", fontWeight: 500, marginLeft: "15px" }} >
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={Reject} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#707070" }}>Reject Request</div>
                                        </div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div className="msg-request-box-receive" onClick={() => handleAcceptRequest()}>
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={Accept} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#ffffff" }}>Accept Request</div>
                                        </div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div className="msg-box" style={{ border: "1px solid #E0E0E0", background: "#ffffff", color: "#2F86D1", fontSize: "16px", fontWeight: 500, marginLeft: "15px" }} >
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={Reject} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#707070" }}>Reject Request</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="responsive-find-list">
                                    <ReceiveList searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} contactModal={contactModal} mobileModal={mobileModal} handleModalIndex={(index) => handleModalIndex(index)} handleImageListModal={(val)=>handleImageListModal(val)} />
                                </div>
                            </div>
                        }
                    </Col>
                    {receiveDetail &&
                        <div className="responsive-find-main">
                            <ReceiveList searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} contactModal={contactModal} mobileModal={mobileModal} handleModalIndex={(index) => handleModalIndex(index)} handleImageListModal={(val)=>handleImageListModal(val)} />
                        </div>
                    }
                </Row>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Row>
                    {(!sendRequestDetail || screenSize.width > 1200) &&
                        <Col xs={12} sm={12} md={7} lg={7} xl={4} xxl={4}>
                            <div className="find-people-search">
                                <Row>
                                    <Col >
                                        <div className={sendRequest && sendRequest.length > 0 ? "search-receive-list" : "manager-card-without-result"}>
                                            {sendRequest && sendRequest.length > 0 ?
                                                sendRequest.map((data, index) => {
                                                    return (
                                                        <div className="d-flex" style={{ paddingLeft: "15px", paddingTop: "10px", paddingBottom: "10px", background: searchId === data.id ? "#D9EFFF" : "", cursor: "pointer" }} onClick={() => handleSearchIdSendRequest(data)}>
                                                            <div>
                                                                <img src={data?.profilePic?.file ? data?.profilePic?.file : DefaultImage} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                            </div>
                                                            <div className="find-heading" >
                                                                <div className="find-main-heading">{data?.name}</div>
                                                                <div className="find-description">{data?.orgnization}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="result-heading" >No results found</div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    }
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={7}>
                        {sendRequestDetail &&
                            <div>
                                <div className="find-people-listing-data">
                                    {screenSize.width < 1200 &&
                                        <img src={ArrowBackChat} alt="back" style={{ cursor: "pointer", marginLeft: "10px" }} onClick={handleArrowchange} />
                                    }
                                    <div className="d-flex justify-content-center listing-data" >
                                        <img src={findDetailList?.profilePic?.file ? findDetailList?.profilePic?.file : DefaultImage} alt="profilePic" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                    </div>
                                    <div className="d-flex justify-content-center ">
                                        <div className="listing-data-heading">{findDetailList?.name}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "13px" }}>
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "20px" }}>
                                        <div className="msg-request-box">
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={requestSend} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#0075CA" }}>Request Sent</div>
                                        </div>
                                        <div className="msg-box" style={{ border: "1px solid #E0E0E0", background: "#ffffff", color: "#2F86D1", fontSize: "16px", fontWeight: 500, marginLeft: "15px" }} >
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={MessageBox} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#707070" }}>Message</div>
                                        </div>
                                    </div>
                                    <div className="email-conatiner mt-3">
                                        <div className="msg-request-box">
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={requestSend} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#0075CA" }}>Request Sent</div>
                                        </div>
                                    </div>
                                    <div className="email-conatiner mt-3">
                                        <div className="msg-box" style={{ border: "1px solid #E0E0E0", background: "#ffffff", color: "#2F86D1", fontSize: "16px", fontWeight: 500, }} >
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={MessageBox} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#707070" }}>Message</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="responsive-find-list">
                                    <SendRequest searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} contactModal={contactModal} mobileModal={mobileModal} handleModalIndex={(index) => handleModalIndex(index)} handleImageListModal={(val)=>handleImageListModal(val)} />
                                </div>
                            </div>
                        }
                    </Col>
                    {sendRequestDetail &&
                        <div className="responsive-find-main">
                            <SendRequest searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} contactModal={contactModal} mobileModal={mobileModal} handleModalIndex={(index) => handleModalIndex(index)} handleImageListModal={(val)=>handleImageListModal(val)} />
                        </div>
                    }
                </Row>
            </TabPanel>
        </>
    )
}

export default FindPeopleTab
